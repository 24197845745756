import React from 'react'
import FilePicker from '../../elements/FilePicker.js'
import KmlFile from '../../../models/KmlFile.js'

export default ({ getFieldError, handleInputChange }) => {
  const handleChange = (file) => {
    const kmlFile = new KmlFile(file)
    handleInputChange('kmlFile')(kmlFile)
  }

  return (
    <FilePicker
      error={!!getFieldError('kmlFile')}
      extensions={['kml', 'kmz']}
      helperText='Upload a kml or kmz file'
      label='Project Boundaries'
      onChange={handleChange}
    />
  )
}
