/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateAsset = /* GraphQL */ `
  subscription OnUpdateAsset($customerId: ID!, $projectId: ID) {
    onUpdateAsset(customerId: $customerId, projectId: $projectId) {
      id
      uuid
      createdAt
      updatedAt
      status
      customerId
      deviceId
      name
      userGroups
      adminGroups
      description
      version
      unitNumber
      make
      model
      year
      capacity
      activatedOn
      deactivatedOn
      toolType
      size
      annualInspection
      type {
        id
        name
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      subType {
        id
        name
        typeId
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      dimensions {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      supplier {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      eventTs
      receivedTs
      firstCheckedIn
      coordinate {
        latitude
        longitude
        __typename
      }
      altitude
      battery
      rssi
      accelerometer
      speed
      course
      projectId
      movingStatus
      movingCoordinate {
        latitude
        longitude
        __typename
      }
      movingTs
      movingStartTs
      movedCoordinate {
        latitude
        longitude
        __typename
      }
      movedTs
      movedProjectId
      __typename
    }
  }
`;
export const onCreateAsset = /* GraphQL */ `
  subscription OnCreateAsset($customerId: ID!, $projectId: ID) {
    onCreateAsset(customerId: $customerId, projectId: $projectId) {
      id
      uuid
      createdAt
      updatedAt
      status
      customerId
      deviceId
      name
      userGroups
      adminGroups
      description
      version
      unitNumber
      make
      model
      year
      capacity
      activatedOn
      deactivatedOn
      toolType
      size
      annualInspection
      type {
        id
        name
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      subType {
        id
        name
        typeId
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      dimensions {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      supplier {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      eventTs
      receivedTs
      firstCheckedIn
      coordinate {
        latitude
        longitude
        __typename
      }
      altitude
      battery
      rssi
      accelerometer
      speed
      course
      projectId
      movingStatus
      movingCoordinate {
        latitude
        longitude
        __typename
      }
      movingTs
      movingStartTs
      movedCoordinate {
        latitude
        longitude
        __typename
      }
      movedTs
      movedProjectId
      __typename
    }
  }
`;
export const onUpdateProject = /* GraphQL */ `
  subscription OnUpdateProject($customerId: ID!) {
    onUpdateProject(customerId: $customerId) {
      id
      name
      customerId
      client
      description
      boundaries {
        latitude
        longitude
        __typename
      }
      overlays {
        name
        __typename
      }
      startDate
      endDate
      status
      userGroups
      adminGroups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateProject = /* GraphQL */ `
  subscription OnCreateProject($customerId: ID!) {
    onCreateProject(customerId: $customerId) {
      id
      name
      customerId
      client
      description
      boundaries {
        latitude
        longitude
        __typename
      }
      overlays {
        name
        __typename
      }
      startDate
      endDate
      status
      userGroups
      adminGroups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAssetMovementCoordinate = /* GraphQL */ `
  subscription OnCreateAssetMovementCoordinate {
    onCreateAssetMovementCoordinate {
      movementId
      source
      index
      eventTs
      latitude
      longitude
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAssetMovementCoordinate = /* GraphQL */ `
  subscription OnUpdateAssetMovementCoordinate {
    onUpdateAssetMovementCoordinate {
      movementId
      source
      index
      eventTs
      latitude
      longitude
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAssetMovementCoordinate = /* GraphQL */ `
  subscription OnDeleteAssetMovementCoordinate {
    onDeleteAssetMovementCoordinate {
      movementId
      source
      index
      eventTs
      latitude
      longitude
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAssetMovementReport = /* GraphQL */ `
  subscription OnCreateAssetMovementReport {
    onCreateAssetMovementReport {
      id
      uuid
      assetId
      startTs
      stopTs
      state
      onRoadDistance
      offRoadDistance
      totalDistanceTraveled
      movedToCoordinate {
        latitude
        longitude
        __typename
      }
      movedFromCoordinate {
        latitude
        longitude
        __typename
      }
      prevProjectId
      destProjectId
      customerId
      firstMove
      userGroups
      adminGroups
      assetTypeId
      assetType {
        id
        name
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      assetSubTypeId
      assetSubType {
        id
        name
        typeId
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAssetMovementReport = /* GraphQL */ `
  subscription OnUpdateAssetMovementReport {
    onUpdateAssetMovementReport {
      id
      uuid
      assetId
      startTs
      stopTs
      state
      onRoadDistance
      offRoadDistance
      totalDistanceTraveled
      movedToCoordinate {
        latitude
        longitude
        __typename
      }
      movedFromCoordinate {
        latitude
        longitude
        __typename
      }
      prevProjectId
      destProjectId
      customerId
      firstMove
      userGroups
      adminGroups
      assetTypeId
      assetType {
        id
        name
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      assetSubTypeId
      assetSubType {
        id
        name
        typeId
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAssetMovementReport = /* GraphQL */ `
  subscription OnDeleteAssetMovementReport {
    onDeleteAssetMovementReport {
      id
      uuid
      assetId
      startTs
      stopTs
      state
      onRoadDistance
      offRoadDistance
      totalDistanceTraveled
      movedToCoordinate {
        latitude
        longitude
        __typename
      }
      movedFromCoordinate {
        latitude
        longitude
        __typename
      }
      prevProjectId
      destProjectId
      customerId
      firstMove
      userGroups
      adminGroups
      assetTypeId
      assetType {
        id
        name
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      assetSubTypeId
      assetSubType {
        id
        name
        typeId
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAssetType = /* GraphQL */ `
  subscription OnCreateAssetType {
    onCreateAssetType {
      id
      name
      subType {
        nextToken
        __typename
      }
      iconName
      idleThreshold
      offlineThreshold
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAssetType = /* GraphQL */ `
  subscription OnUpdateAssetType {
    onUpdateAssetType {
      id
      name
      subType {
        nextToken
        __typename
      }
      iconName
      idleThreshold
      offlineThreshold
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAssetType = /* GraphQL */ `
  subscription OnDeleteAssetType {
    onDeleteAssetType {
      id
      name
      subType {
        nextToken
        __typename
      }
      iconName
      idleThreshold
      offlineThreshold
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAssetSubType = /* GraphQL */ `
  subscription OnCreateAssetSubType {
    onCreateAssetSubType {
      id
      name
      typeId
      iconName
      idleThreshold
      offlineThreshold
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAssetSubType = /* GraphQL */ `
  subscription OnUpdateAssetSubType {
    onUpdateAssetSubType {
      id
      name
      typeId
      iconName
      idleThreshold
      offlineThreshold
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAssetSubType = /* GraphQL */ `
  subscription OnDeleteAssetSubType {
    onDeleteAssetSubType {
      id
      name
      typeId
      iconName
      idleThreshold
      offlineThreshold
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDimensions = /* GraphQL */ `
  subscription OnCreateDimensions {
    onCreateDimensions {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDimensions = /* GraphQL */ `
  subscription OnUpdateDimensions {
    onUpdateDimensions {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDimensions = /* GraphQL */ `
  subscription OnDeleteDimensions {
    onDeleteDimensions {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSupplier = /* GraphQL */ `
  subscription OnCreateSupplier {
    onCreateSupplier {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSupplier = /* GraphQL */ `
  subscription OnUpdateSupplier {
    onUpdateSupplier {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSupplier = /* GraphQL */ `
  subscription OnDeleteSupplier {
    onDeleteSupplier {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCustomer = /* GraphQL */ `
  subscription OnCreateCustomer {
    onCreateCustomer {
      id
      name
      email
      description
      status
      assetsVersion
      projects {
        nextToken
        __typename
      }
      assets {
        nextToken
        __typename
      }
      userGroups
      adminGroups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCustomer = /* GraphQL */ `
  subscription OnUpdateCustomer {
    onUpdateCustomer {
      id
      name
      email
      description
      status
      assetsVersion
      projects {
        nextToken
        __typename
      }
      assets {
        nextToken
        __typename
      }
      userGroups
      adminGroups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCustomer = /* GraphQL */ `
  subscription OnDeleteCustomer {
    onDeleteCustomer {
      id
      name
      email
      description
      status
      assetsVersion
      projects {
        nextToken
        __typename
      }
      assets {
        nextToken
        __typename
      }
      userGroups
      adminGroups
      createdAt
      updatedAt
      __typename
    }
  }
`;
