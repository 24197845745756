import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  ListItemButton,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import AccountNav from './AccountNav.js'
import SystemIcon from '../../elements/SystemIcon.js'
import CustomerSelector from '../../elements/CustomerSelector.js'

import { useStateContext } from '../../../store/stateContext.js'
import { useAuthActions } from '../../../store/Auth/AuthActions.js'
import { useUIActions } from '../../../store/UI/UIActions.js'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDrawer-paper': {
     backgroundColor: theme.colors.backgroundColor,
     color: 'white',
     width: 275
    },
  },
  button: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(5),
  },
  list: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(5),
  },
  drawerContentWrap: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-evenly',
  },
  customerSelectorWrap: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}))

export default () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { memberOf, signOut } = useAuthActions()
  const { closeDrawerNav } = useUIActions()
  const {
    state: { projects: projectsState, ui: uiState },
  } = useStateContext()

  const handleSignOut = () => {
    signOut()
    navigate('/')
  }

  return (
    <Drawer className={classes.root} open={uiState.drawerNavOpen} onClose={closeDrawerNav}>
      <div className={classes.drawerContentWrap}>
        {memberOf('admins') && (
          <div className={classes.customerSelectorWrap}>
            <CustomerSelector />
          </div>
        )}
        <List className={classes.list} onClick={closeDrawerNav}>
          <Link to='/'>
            <ListItem >
              <ListItemButton>
                <ListItemIcon>
                  <SystemIcon color='white' name='icn_map' />
                </ListItemIcon>
                <ListItemText>MAP</ListItemText>
              </ListItemButton>
            </ListItem>
          </Link>
          {(memberOf(/admins|customerAdmin[\S]+/) ||
            projectsState.projectList.length > 0) && (
              <Link to='/Projects'>
                <ListItem to='/Projects' data-test='projectsButton'>
                  <ListItemButton>
                    <ListItemIcon>
                      <SystemIcon color='white' name='icn_project' />
                    </ListItemIcon>
                    <ListItemText>PROJECTS</ListItemText>
                  </ListItemButton>
                </ListItem>
              </Link>
            )}
        </List>
        <AccountNav />
        <Button
          color='secondary'
          className={classes.button}
          data-test='signoutButton'
          onClick={handleSignOut}
        >
          Sign out
        </Button>
      </div>
    </Drawer>
  )
}
