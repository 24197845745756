import React, { useState } from 'react'
import { useUIActions } from '../../store/UI/UIActions.js'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid2,
  Typography,
  LinearProgress,
} from '@mui/material'
// import { FilePicker } from 'react-file-picker'
import FilePicker from '../elements/FilePicker.js'
import SpinnerButton from '../elements/SpinnerButton.js'
import { useForm } from '../../hooks/Forms.js'
import { useStateContext } from '../../store/stateContext.js'
import { useProjectActions } from '../../store/Project/ProjectActions.js'
import KmlFile from '../../models/KmlFile.js'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiPaper-root': {
      borderRadius: '16px',
      border: `1px solid ${theme.colors.lightSteel}`,
    },
  },
  dialogBackground: {
    backgroundColor: theme.colors.backgroundColor
  },
  label: {
    '&.MuiStepLabel-root .MuiStepLabel-label': {
      color: 'white',
    },
  },

}))

const UploadOverlayDialog = ({ project, onSubmit }) => {
  const classes = useStyles()

  const { state } = useStateContext()
  const { uploadOverlay } = useProjectActions()
  const { closeUploadOverlayDialog } = useUIActions()
  const {
    formState,
    getFieldError,
    getValue,
    handleInputChange,
    handleSubmit,
    isValid,
  } = useForm({
    file: 'required',
  })

  const [sending, setSending] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)

  const closeForm = () => {
    closeUploadOverlayDialog()
  }

  const submitForm = async ({ file }) => {
    try {
      setSending(true)

      const kmlFile = new KmlFile(file)
      const structure = await kmlFile.toGeoJsonWithStructure()

      const updatedProject = await uploadOverlay({
        project,
        name: `${project.id}-${file.name}`,
        directory: `${project.customerId}/${project.id}`,
        file,
        structure,
        progressCallback({ transferredBytes, totalBytes }) {
          setUploadProgress(100 * (transferredBytes / totalBytes))
        },
      })
      closeForm()
      onSubmit(updatedProject)
      setSending(false)
    } catch (err) {
      setSending(false)
      throw err
    }
  }

  return (
    <Dialog
      fullWidth
      scroll='body'
      open={state.ui.uploadOverlayDialogOpen}
      onClose={closeUploadOverlayDialog}
      className={classes.dialog}
    >
      <form onSubmit={(e) => handleSubmit(submitForm, e)} className={classes.dialogBackground}>
        <DialogContent>
          <DialogTitle sx={{ color: 'white' }}>Upload Overlay</DialogTitle>
          {sending ? (
            <LinearProgress variant={'determinate'} value={uploadProgress} />
          ) : (
            <Grid2 container spacing={1} alignItems='center' >
              {formState.error && (
                <Grid2 >
                  <Typography variant='body1' color='error'>
                    {formState.error}
                  </Typography>
                </Grid2>
              )}
              <Grid2 xs={12} sx={{ width: '100%' }}>
                <FilePicker
                  error={!!getFieldError('file')}
                  extensions={['kml', 'kmz']}
                  file={getValue('file')}
                  label='Overlay File'
                  helperText='Upload a kml or kmz file'
                  onChange={handleInputChange('file')}
                />
              </Grid2>
            </Grid2>
          )}
        </DialogContent>
        <DialogActions>
          <Button color='secondary' onClick={closeForm}>
            Cancel
          </Button>
          <SpinnerButton loading={sending} type='submit' valid={isValid()}>
            Submit
          </SpinnerButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default UploadOverlayDialog
