export const INIT_CUSTOMERS = 'INIT_CUSTOMERS'
export const SET_CUSTOMERS = 'SET_CUSTOMERS'
export const SET_ACTIVE_CUSTOMER = 'SET_ACTIVE_CUSTOMER'
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER'
export const ADD_CUSTOMER = 'ADD_CUSTOMER'

export const initialState = {
  activeCustomer: null,
  customerList: [],
}

export const reducer = (state = initialState, { type, payload }) => {

  switch (type) {
    case INIT_CUSTOMERS:
      return {
        ...initialState,
      }
    case SET_ACTIVE_CUSTOMER:
      return {
        ...state,
        activeCustomer: payload.activeCustomer,
      }
    case SET_CUSTOMERS:
      return {
        ...state,
        customerList: payload.customerList || [],
      }
    case UPDATE_CUSTOMER:
      return {
        ...state,
        customerList: state.customerList.map((c) => {
          if (c.id === payload.updatedCustomer.id) {
            return payload.updatedCustomer
          }
          return c
        }),
      }
    case ADD_CUSTOMER:
      return {
        ...state,
        customerList: [...state.customerList, payload.addCustomer]
      }
    default:
      return state
  }
}
