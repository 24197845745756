import React, { createContext, useContext, useReducer } from 'react'
import { reducer, initialState } from './reducers.js'

const Context = createContext({ state: initialState, dispatch: () => {} })

export const useStateContext = () => useContext(Context)

export const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  )
}
