import React, { useEffect, useState } from 'react'
import { SvgIcon } from '@mui/material'
import SVG from 'react-inlinesvg'
import getIcon from '../../libs/getIcon.js'

const AssetIcon = ({ name, color = 'white' }) => {
  const [icon, setIcon] = useState('/static/media/question_mark.cca69238205baddfed4e.svg');

  useEffect(() => {
    const loadIcon = async () => {
      try {
        let response = await getIcon(name + '.svg')
        let iconSource = await response
        if (typeof iconSource === 'undefined') {
          const questionResponse = await getIcon('question_mark.svg')
          iconSource = await questionResponse
        }
        setIcon(iconSource)
      } catch (error) {
        console.error(error)
      }
    }
    loadIcon()
  }, [name])

  return <SvgIcon color={color}>
    <SVG key={icon} src={icon} />
  </SvgIcon>
}

export default AssetIcon
