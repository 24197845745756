import { combineReducers } from './combineReducers.js'
import { reducer as assetsReducer } from './Assets/AssetsReducer.js'
import { reducer as authReducer } from './Auth/AuthReducer.js'
import { reducer as customerReducer } from './Customer/CustomerReducer.js'
import { reducer as projectReducer } from './Project/ProjectReducer.js'
import { reducer as uiReducer } from './UI/UIReducer.js'
import { reducer as mapReducer } from './Map/MapReducer.js'
import { reducer as reportsReducer } from './Reports/ReportsReducer.js'
import { reducer as userReducer } from './User/UserReducer.js'
import { reducer as searchReducer } from './Search/SearchReducer.js'
import { reducer as constantsReducer } from './Constants/ConstantsReducer.js'

const [reducer, initialState] = combineReducers({
  assets: assetsReducer,
  auth: authReducer,
  customers: customerReducer,
  projects: projectReducer,
  ui: uiReducer,
  map: mapReducer,
  reports: reportsReducer,
  users: userReducer,
  search: searchReducer,
  constants: constantsReducer,
})

export { reducer, initialState }
