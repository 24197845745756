/* eslint-disable no-unused-vars */

import React from 'react'
import clsx from 'clsx'
import { IconButton, SvgIcon } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Icons from '../../assets/icons/symbol-defs.svg'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  alert: {
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    top: 2,
    right: 2,
    height: theme.spacing(1),
    width: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
  },
  /* eslint-disable quote-props */
  lit: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    /* eslint-enable quote-props */
  },
}))

const ToolbarToggleButton = React.forwardRef((props, ref) => {
  const classes = useStyles()
  const lit = props.lit || false

  return (
    <>
      <IconButton
        id={props.id}
        onClick={props.onClick}
        ref={ref}
        size='small'
        className={clsx(classes.root, {
          [classes.lit]: lit,
        })}
      // sx={{
      //   marginLeft: 2
      // }}
      >
        {props.alert && !props.lit && <div className={classes.alert} />}
        <SvgIcon color='secondary' sx={{ fill: 'white' }}>
          <svg>
            <use xlinkHref={Icons + props.icon} />
          </svg>
        </SvgIcon>
      </IconButton>
    </>
  )
})

export default ToolbarToggleButton
