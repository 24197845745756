import React from 'react'

import { useStateContext } from './store/stateContext.js'
import Layout from './components/Layout.js'
import LoginPage from './components/pages/LoginPage.js'

const App = () => {
  const { state } = useStateContext()

  return state.auth.authenticated ? <Layout /> : <LoginPage />
}

export default App
