import React, { useState, useEffect, useRef } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs/index.js'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid2,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { safeGet } from '../../util.js'
import { useStateContext } from '../../store/stateContext.js'
import { useUIActions } from '../../store/UI/UIActions.js'
import { useAssetsActions } from '../../store/Assets/AssetsActions.js'
import { useForm } from '../../hooks/Forms.js'
import SpinnerButton from '../elements/SpinnerButton.js'

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { useConstantsActions } from '../../store/Constants/ConstantsActions.js'
import {
  canceledPromiseErrorName,
  useCancellablePromise,
} from '../../promiseUtils.js'

const useStyles = makeStyles(() => ({
  datepicker: {
    width: '100%',
  },
}))

const EditAssetDialog = ({ asset, onSubmit }) => {
  const inputLabelType = useRef()
  const inputLabelSubtype = useRef()
  const inputLabelDimensions = useRef()
  const inputLabelSupplier = useRef()
  const classes = useStyles()
  asset = asset || {}
  const { cancellablePromise } = useCancellablePromise()

  const [shouldInit, setShouldInit] = useState(true)
  const [assetSubtypes, setAssetSubtypes] = useState([])
  const [assetTypes, setAssetTypes] = useState([])
  const [dimensions, setDimensions] = useState([])
  const [suppliers, setSuppliers] = useState([])

  const [selectedType, setSelectedType] = useState()
  const [selectedSubType, setSelectedSubType] = useState()
  const [selectedDimensions, setSelectedDimensions] = useState()
  const [selectedSupplier, setSelectedSupplier] = useState()
  const { closeEditAssetDialog } = useUIActions()
  const { editAsset } = useAssetsActions()
  const { getConstants } = useConstantsActions()

  // app level state
  const [sending, setSending] = useState(false)
  const { state } = useStateContext()

  // component level state
  const {
    inputs,
    setInputs,
    clearFormState,
    formState,
    getFieldError,
    getValue,
    handleInputChange,
    handleSubmit,
    isValid,
    validateForm,
  } = useForm({})

  // update asset name if changed
  useEffect(() => {
    setInputs({
      ...inputs,
      name: asset.unitNumber,
      description: asset.description,
      dimensions: asset.dimensions,
      supplier: asset.supplier,
      make: asset.make,
      model: asset.model,
      year: asset.year,
      capacity: asset.capacity,
      annualInspection: asset.annualInspection,
    })

    validateForm()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    asset.unitNumber,
    asset.description,
    asset.dimensions,
    asset.supplier,
    asset.make,
    asset.model,
    asset.year,
    asset.capacity,
    asset.annualInspection,
  ])

  const getTypeFromId = (id, isSubtype) => {
    if (!assetTypes || assetTypes.length === 0) return
    if (isSubtype) {
      return getTypeFromId(selectedType.id).subType.items.find(
        (asset) => asset.id === id,
      )
    }
    return assetTypes.find((asset) => asset.id === id)
  }

  const getObjectFromId = (array, id) => {
    if (id === '') return { name: '', value: '' }
    return array.find((item) => item.id === id)
  }

  const handleTypeChange = (type) => {
    if (type) {
      setSelectedType(type)
      setAssetSubtypes(type.subType.items)
      setSelectedSubType({})
    }
  }

  // component level actions
  const clearForm = () => {
    setSending(false)
    setShouldInit(true)
    clearFormState()
  }

  const cancelForm = () => {
    closeEditAssetDialog()
    setShouldInit(true)
    clearForm()
  }

  /**
   * Submits the form
   * @param {object} form  Object containing form inputs
   */
  const submitForm = async () => {
    try {
      setSending(true)

      await editAsset({
        id: asset.id,
        assetTypeId: selectedType.id,
        assetSubTypeId: safeGet('id', selectedSubType) || null,
        assetDimensionsId: safeGet('id', selectedDimensions) || null,
        assetSupplierId: safeGet('id', selectedSupplier) || null,
        unitNumber: safeGet('unitNumber', inputs, null),
        description: safeGet('description', inputs, null),
        make: safeGet('make', inputs, null),
        model: safeGet('model', inputs, null),
        year: safeGet('year', inputs, null),
        capacity: safeGet('capacity', inputs, null),
        annualInspection: safeGet('annualInspection', inputs, null),
      }).then((res) => {
        // call onSubmit prop
        if (typeof onSubmit === 'function') {
          onSubmit(res)
        }
      })
      closeEditAssetDialog()
      clearForm()
    } catch (err) {
      setSending(false)
      throw err
    }
  }

  // initialize type and subType
  useEffect(() => {
    setSelectedType(asset.type)
    setSelectedSubType(safeGet('subType', asset) || {})

    if (!asset || !asset.type || !asset.type.id) return
    if (!assetTypes || assetTypes.length === 0) return

    const type = getTypeFromId(asset.type.id)
    setAssetSubtypes(type.subType.items)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetTypes, asset.type, asset.subType])

  // initialize dimensions
  useEffect(() => {
    setSelectedDimensions(asset.dimensions)
    setSelectedSupplier(asset.supplier)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensions, suppliers, asset.dimensions, asset.supplier])

  useEffect(() => {
    if (state.ui.editAssetDialogOpen && shouldInit) {
      cancellablePromise(getConstants())
        .then((result) => {
          setAssetTypes(result.assetTypes)
          setDimensions(result.dimensions)
          setSuppliers(result.suppliers)
        })
        .catch((error) => {
          if (error.name === canceledPromiseErrorName) {
            return
          }
          console.error(error)
        })
      setShouldInit(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.ui.editAssetDialogOpen])

  // run the validator so the required field is recognized
  useEffect(() => {
    validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.ui.editAssetDialogOpen])

  return (
    <>
      {asset && selectedType && selectedSubType && (
        <Dialog
          fullWidth
          scroll='body'
          open={state.ui.editAssetDialogOpen}
          onClose={closeEditAssetDialog}
        >
          {
            // make loading spinner here
            // set it up so that it is done loading when constants have been fetched.
          }
          <form onSubmit={(e) => handleSubmit(submitForm, e)}>
            <DialogContent>
              <DialogTitle>EDIT ASSET</DialogTitle>

              <Grid2 container direction='row' spacing={2}>
                <Grid2 xs={12}>
                  <TextField
                    autoComplete='off'
                    autoFocus
                    error={!!getFieldError('unitNumber')}
                    helperText={getFieldError('unitNumber')}
                    id='asset-unitNumber'
                    label='Unit ID'
                    fullWidth
                    margin='normal'
                    onChange={handleInputChange('unitNumber')}
                    value={getValue('unitNumber', asset.unitNumber)}
                    variant='outlined'
                  />
                </Grid2>
                <Grid2 container spacing={2} alignItems='flex-end' xs={12}>
                  <Grid2 xs={12} sm={6}>
                    <FormControl
                      variant='outlined'
                      className={classes.formControl}
                      fullWidth
                    >
                      <InputLabel ref={inputLabelType}>Type</InputLabel>
                      <Select
                        value={selectedType.id}
                        // labelWidth={
                        //   inputLabelType.current
                        //     ? inputLabelType.current.offsetWidth
                        //     : 0
                        // }
                        onChange={(e) => {
                          handleTypeChange(getTypeFromId(e.target.value))
                        }}
                      >
                        {assetTypes &&
                          assetTypes.map((type, index) => (
                            <MenuItem
                              key={'type-' + index}
                              value={type.id}
                              name={type.name}
                            >{`${type.name}`}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid2>
                  <Grid2 xs={12} sm={6}>
                    <FormControl
                      variant='outlined'
                      className={classes.formControl}
                      fullWidth
                    >
                      <InputLabel ref={inputLabelSubtype}>Subtype</InputLabel>
                      <Select
                        value={selectedSubType.id}
                        // labelWidth={
                        //   inputLabelSubtype.current
                        //     ? inputLabelSubtype.current.offsetWidth
                        //     : 0
                        // }
                        onChange={(e) => {
                          setSelectedSubType(
                            getTypeFromId(e.target.value, true),
                          )
                        }}
                      >
                        {assetSubtypes &&
                          assetSubtypes.map((subtype, index) => (
                            <MenuItem
                              key={'subtype-' + index}
                              value={subtype.id}
                            >{`${subtype.name}`}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid2>
                </Grid2>
                <Grid2 xs={12}>
                  <TextField
                    autoComplete='off'
                    error={!!getFieldError('description')}
                    fullWidth
                    helperText={getFieldError('description')}
                    id='asset-description'
                    label='Description'
                    margin='normal'
                    multiline
                    onChange={handleInputChange('description')}
                    maxRows='4'
                    name='description'
                    value={getValue('description', asset.description)}
                    variant='outlined'
                  />
                </Grid2>
                <Grid2 xs={12} sm={6}>
                  <FormControl
                    variant='outlined'
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel ref={inputLabelDimensions}>
                      Dimensions
                    </InputLabel>
                    <Select
                      value={safeGet('id', selectedDimensions) || ''}
                      // labelWidth={
                      //   inputLabelDimensions.current
                      //     ? inputLabelDimensions.current.offsetWidth
                      //     : 0
                      // }
                      onChange={(e) => {
                        setSelectedDimensions(
                          getObjectFromId(dimensions, e.target.value),
                        )
                      }}
                    >
                      <MenuItem key={'dimension-empty'} value={''} name={''}>
                        None
                      </MenuItem>
                      {dimensions &&
                        dimensions.map((dimension, index) => (
                          <MenuItem
                            key={'dimensions-' + index}
                            value={safeGet('id', dimension)}
                            name={safeGet('name', dimension)}
                          >{`${safeGet('name', dimension)}`}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid2>
                <Grid2 xs={12} sm={6}>
                  <FormControl
                    variant='outlined'
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel ref={inputLabelSupplier}>Supplier</InputLabel>
                    <Select
                      value={safeGet('id', selectedSupplier) || ''}
                      // labelWidth={
                      //   inputLabelSupplier.current
                      //     ? inputLabelSupplier.current.offsetWidth
                      //     : 0
                      // }
                      onChange={(e) => {
                        setSelectedSupplier(
                          getObjectFromId(suppliers, e.target.value),
                        )
                      }}
                    >
                      <MenuItem key={'supplier-empty'} value={''} name={''}>
                        None
                      </MenuItem>
                      {suppliers &&
                        suppliers.map((supplier, index) => (
                          <MenuItem
                            key={'suppliers-' + index}
                            value={safeGet('id', supplier)}
                            name={safeGet('name', supplier)}
                          >{`${safeGet('name', supplier)}`}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid2>
                <Grid2 xs={12} sm={6}>
                  <TextField
                    autoComplete='off'
                    error={!!getFieldError('make')}
                    fullWidth
                    helperText={getFieldError('make')}
                    id='asset-make'
                    label='Make'
                    margin='normal'
                    multiline
                    onChange={handleInputChange('make')}
                    maxRows='4'
                    name='make'
                    value={getValue('make', asset.make)}
                    variant='outlined'
                  />
                </Grid2>
                <Grid2 xs={12} sm={6}>
                  <TextField
                    autoComplete='off'
                    error={!!getFieldError('model')}
                    fullWidth
                    helperText={getFieldError('model')}
                    id='asset-model'
                    label='Model'
                    margin='normal'
                    multiline
                    onChange={handleInputChange('model')}
                    maxRows='4'
                    name='model'
                    value={getValue('model', asset.model)}
                    variant='outlined'
                  />
                </Grid2>
                <Grid2 xs={12} sm={6}>
                  <TextField
                    autoComplete='off'
                    error={!!getFieldError('year')}
                    fullWidth
                    helperText={getFieldError('year')}
                    id='asset-year'
                    label='Year'
                    margin='normal'
                    multiline
                    onChange={handleInputChange('year')}
                    maxRows='4'
                    name='year'
                    value={getValue('year', asset.year)}
                    variant='outlined'
                  />
                </Grid2>
                <Grid2 xs={12} sm={6}>
                  <TextField
                    autoComplete='off'
                    error={!!getFieldError('capacity')}
                    fullWidth
                    helperText={getFieldError('capacity')}
                    id='asset-capacity'
                    label='Capacity'
                    margin='normal'
                    multiline
                    onChange={handleInputChange('capacity')}
                    maxRows='4'
                    name='capacity'
                    value={getValue('capacity', asset.capacity)}
                    variant='outlined'
                  />
                </Grid2>
                {/* <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="off"
                    error={!!getFieldError('form')}
                    fullWidth
                    helperText={getFieldError('form')}
                    id="asset-form"
                    label="Form"
                    margin="normal"
                    multiline
                    onChange={handleInputChange('form')}
                    rowsMax="4"
                    name="form"
                    value={getValue('form', asset.form)}
                    variant="outlined"
                  />
                </Grid> */}
                <Grid2 container xs={12} sm={6} spacing={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid2 xs={12}>
                      <DatePicker
                        className={classes.datepicker}
                        // disableToolbar
                        format='MM/dd/yyyy'
                        id='annual-inspection-date'
                        KeyboardButtonProps={{
                          'aria-label': 'annual inspection date',
                        }}
                        label='Annual Inspection Date'
                        margin='normal'
                        name='annualInspection'
                        onChange={handleInputChange('annualInspection')}
                        value={getValue(
                          'annualInspection',
                          asset.annualInspection,
                        )}
                      // variant='inline'
                      />
                    </Grid2>
                  </LocalizationProvider>
                </Grid2>
                {/* <Grid item xs={12}>
                  <TextField
                    autoComplete="off"
                    error={!!getFieldError('notes')}
                    fullWidth
                    helperText={getFieldError('notes')}
                    id="asset-notes"
                    label="Notes"
                    margin="normal"
                    multiline
                    onChange={handleInputChange('notes')}
                    rowsMax="4"
                    name="notes"
                    value={getValue('notes', asset.notes)}
                    variant="outlined"
                  />
                </Grid> */}
              </Grid2>
              {formState.error && (
                <Grid2 >
                  <Typography variant='body1' color='error'>
                    {formState.error}
                  </Typography>
                </Grid2>
              )}
            </DialogContent>
            <DialogActions>
              <Button color='secondary' onClick={cancelForm}>
                Cancel
              </Button>
              <SpinnerButton loading={sending} type='submit' valid={isValid()}>
                Submit
              </SpinnerButton>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </>
  )
}

export default EditAssetDialog
