import { useStateContext } from '../stateContext.js'
import { SET_QUICK_SEARCH_RESULTS } from './SearchReducer.js'
import * as queries from '../../graphql/queries.js'
import { generateClient } from 'aws-amplify/api'

export const useSearchActions = () => {
  const { state, dispatch } = useStateContext()

  const client = generateClient();

  const getSearchResults = async (value) => {
    const customerId = state.customers.activeCustomer.id
    const lowerCaseValue = value.toLowerCase()
    let projectsLowerCase = await client.graphql(
      {
        query: queries.searchProjects,
        variables: {
          filter: {
            status: { eq: 'active' },
            customerId: { eq: customerId?.toLowerCase() },
            or: [
              {
                name: {
                  wildcard: '*' + lowerCaseValue + '*',
                },
              },
              {
                client: {
                  wildcard: '*' + lowerCaseValue + '*',
                },
              },
            ],
          },
        }
      }
    )
    projectsLowerCase = projectsLowerCase.data.searchProjects.items

    const projects = Array.from(
      new Set(projectsLowerCase.map((project) => project.id)),
    ).map((id) => {
      return projectsLowerCase.find((project) => project.id === id)
    })

    let assetsLowerCase = await client.graphql(
      {
        query: queries.searchAssets,
        variables: {
          filter: {
            status: { eq: 'active' },
            customerId: { eq: customerId?.toLowerCase() },
            or: [
              {
                name: {
                  wildcard: '*' + lowerCaseValue + '*',
                },
              },
              {
                uuid: {
                  wildcard: '*' + lowerCaseValue + '*',
                },
              },
              {
                make: {
                  wildcard: '*' + lowerCaseValue + '*',
                },
              },
              {
                model: {
                  wildcard: '*' + lowerCaseValue + '*',
                },
              },
              {
                unitNumber: {
                  wildcard: '*' + lowerCaseValue + '*',
                },
              },
            ],
          },
          historyLimit: 1,
        }
      }
    )

    assetsLowerCase = assetsLowerCase.data.searchAssets.items

    const assets = Array.from(
      new Set(assetsLowerCase.map((asset) => asset.id)),
    ).map((id) => {
      return assetsLowerCase.find((asset) => asset.id === id)
    })

    const quickSearchResults = { projects, assets }

    if (quickSearchResults) {
      dispatch({
        type: SET_QUICK_SEARCH_RESULTS,
        payload: { quickSearchResults },
      })
    }

    return quickSearchResults
  }

  return {
    getSearchResults,
    searchState: state.search,
  }
}
