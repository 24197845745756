import JSZip from 'jszip'
import { kml as toGeoJson } from '@tmcw/togeojson'

export default class KmlFile {
  /**
   * @param {File} file
   */
  constructor(file) {
    if (file instanceof Document) {
      this.domDocument = file

      const serializer = new XMLSerializer()
      const rawXml = serializer.serializeToString(file.documentElement)

      file = new File([rawXml], 'kml-file', {
        type: 'application/vnd.google-earth.kml+xml',
      })
    }

    if (!(file instanceof File)) {
      throw new Error('Expected type of File but got an ' + typeof file)
    }
    this.file = file
  }

  /**
   * Gets an object of coordinates keyed by layers
   * @return {object}
   */
  async getLayers() {
    try {
      const geoJson = await this.toGeoJson()
      return geoJson.features.reduce((acc, cur) => {
        acc[cur.properties.name] = cur.geometry.coordinates
        return acc
      }, {})
    } catch (error) {
      throw new Error(error)
    }
  }

  async cleanDocument(doc) {
    try {
      //const docClone = doc.clone()
      //const docClone = doc.implementation.createDocument(doc, null, null)
      //  let newDoc = document.implementation.createDocument('http://www.opengis.net/kml/2.2', 'kml', null);
      //const newDocNode = docClone.importNode(doc.querySelector('Document').cloneNode(true), true)
      //docClone.appendChild(newDocNode)
      let docClone = doc.cloneNode(true)
      // newDoc.importNode(docClone, true)

      // remove all folders
      const folders = docClone.querySelectorAll(':scope > Folder')
      for (let folder of folders) {
        folder.parentNode.removeChild(folder)
      }

      const documents = docClone.querySelectorAll(':scope > Document')
      for (let document of documents) {
        document.parentNode.removeChild(document)
      }
      const placemarks = docClone.querySelectorAll(':scope > Placemark')
      for (let placemark of placemarks) {
        placemark.parentNode.removeChild(placemark)
      }

      return docClone
    } catch (error) {
      throw new Error(error)
    }
  }

  createNewDocument(cleanedDoc) {
    try {
      let dom = document.implementation.createDocument('', '', null)
      let node = dom.appendChild(
        dom.createElementNS('http://www.opengis.net/kml/2.2', 'kml'),
      )
      node.setAttribute('xmlns', 'http://www.opengis.net/kml/2.2')
      let cleanedClone = cleanedDoc.cloneNode(true)
      node.appendChild(cleanedClone)
      return dom
    } catch (error) {
      throw new Error(error)

    }
  }

  async convertKmlToGeoJSON(doc, document) {
    try {
      const cleanedDoc = await this.cleanDocument(document)

      const nameTag = document.querySelector('name')
      const name = nameTag ? nameTag.textContent : document.tagName
      let kmlLayerAsGeoJSON = {
        name: name,
        children: [],
        geoJson: null,
      }
      const nestedDocument = document.querySelector('Document')
      if (nestedDocument) {
        const convertedLayer = await this.convertKmlToGeoJSON(
          doc,
          nestedDocument.cloneNode(true),
        )
        kmlLayerAsGeoJSON.children.push(convertedLayer)
      }

      const folders = document.querySelectorAll(':scope > Folder')
      for (const folder of folders) {
        let dom = this.createNewDocument(cleanedDoc)
        dom.querySelector('Document').appendChild(folder)
        const geoJson = await toGeoJson(dom, { styles: true })
        const folderNameTag = folder.querySelector('name')
        const folderName = folderNameTag
          ? folderNameTag.textContent
          : folder.tagName
        let folderKmlLayerAsGeoJSON = {
          name: folderName,
          children: geoJson.features.map((feature) => {
            return {
              name: feature.properties.name,
              children: [],
              geoJson: feature,
            }
          }),
          geoJson: null,
        }
        kmlLayerAsGeoJSON.children.push(folderKmlLayerAsGeoJSON)
      }

      const placemarks = document.querySelectorAll(':scope > Placemark')
      let placeMarkDom = this.createNewDocument(cleanedDoc)
      for (const placemark of placemarks) {
        placeMarkDom.querySelector('Document').appendChild(placemark)
      }
      const geoJson = await toGeoJson(placeMarkDom, { styles: true })
      kmlLayerAsGeoJSON.children.push(
        ...geoJson.features.map((feature) => {
          if (feature.geometry.type === 'Polygon') {
            feature.properties.fill = '#ffffff'
          }
          return {
            name: feature.properties.name,
            geoJson: feature,
            children: [],
          }
        }),
      )
      // const document = doc.querySelectorAll(':scope > Document')

      //doc.querySelector()
      return kmlLayerAsGeoJSON
    } catch (error) {
      throw new Error(error)
    }

  }

  // async getStructure(options = {}) {

  //   // const geoJson = await toGeoJson(doc, { styles: true })
  //   return new KmlLayer({
  //     ...options,
  //     kmlLayerAsGeoJson: kmlAsGeoJson,
  //   })
  // }

  async getAsGeoJson() {
    try {
      const doc = await this.toDom()
      return await toGeoJson(doc, { styles: true })
    } catch (error) {
      throw new Error(error)
    }
  }

  async toGeoJsonWithStructure() {
    try {
      const doc = await this.toDom()
      return await this.convertKmlToGeoJSON(
        doc,
        doc.querySelector('Document').cloneNode(true),
      )

    } catch (error) {
      throw new Error(error)
    }
  }

  /**
   * Outputs file as GeoJSON
   * @return {object}
   */
  async toGeoJson() {
    try {
      switch (this.file.type) {
        case 'application/json':
          return JSON.parse(this.toString)
        case 'application/vnd.google-earth.kmz':
        case 'application/vnd.google-earth.kml+xml':
          {
            const kml = await this.toDom()
            const geoJson = await toGeoJson(kml, { styles: true })
            return geoJson
          }
        default:
          throw new Error('Mime type unsupported')
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  /**
   * Outputs file as string
   */
  toString() {
    return new Promise((resolve, reject) => {
      try {
        let extension = this.file.name.split('.').pop()
        switch (extension) {
          case 'kmz':
            resolve(this.unzip(this.file))
            break
          default:
            {
              const reader = new FileReader()
              reader.onload = (e) => {
                resolve(e.target.result)
              }

              reader.onerror = () => {
                reader.abort()
                reject(reader.error)
              }

              reader.readAsText(this.file)
            }
        }
      } catch (err) {
        reject(err)
      }
    })
  }

  /**
   * Outputs file as a DOM object
   */
  async toDom() {
    if (!this.domDocument) {
      let kmlStr = await this.toString()
      kmlStr = kmlStr
        .replace(/<kml[^>]*>/g, '<kml xmlns="http://www.opengis.net/kml/2.2">')
        .replace(/<Document[^>]*>/g, '<Document>')

      const parser = new DOMParser()
      const dom = parser.parseFromString(kmlStr, 'text/xml')

      // check for parse errors
      const parsererrorNS = parser
        .parseFromString('INVALID', 'text/xml')
        .getElementsByTagName('parsererror')[0].namespaceURI
      if (dom.getElementsByTagNameNS(parsererrorNS, 'parsererror').length > 0) {
        throw new Error('Kml file is invalid')
      }

      this.domDocument = dom
    }

    return this.domDocument
  }

  /**
   * Unzips KMZ file
   * @param {File} kmzFile
   * @return {File} KML file
   */
  async unzip(kmzFile) {
    try {
      const zip = await JSZip.loadAsync(kmzFile)

      const fileName = Object.keys(zip.files).shift()
      const file = zip.file(fileName)
      // const buffer = await file.async('nodebuffer')
      // const fileContent = new TextDecoder('utf-8').decode(buffer)

      const fileContent = await file.async('text')
      // Return first file in KMZ
      return fileContent
    } catch (error) {
      throw new Error(error)
    }
  }
}
