import React, { useEffect, useState } from 'react'

import moment from 'moment'

import {
  Container,
  Drawer,
  Typography,
  Grid2,
  IconButton
} from '@mui/material'
import { makeStyles } from '@mui/styles'

import SystemIcon from './SystemIcon.js'
import { MaterialReactTable } from 'material-react-table'

import { useStateContext } from '../../store/stateContext.js'
import { safeGet } from '../../util.js'
import { useUIActions } from '../../store/UI/UIActions.js'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDrawer-paper': {
      maxHeight: '83vh',
      borderTopWidth: 1,
      borderTopColor: theme.colors.lightSteel,
      borderTopStyle: 'solid',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },
  title: {
    color: theme.colors.portlandCement,
    textTransform: 'uppercase',
  },
  closeButton: {
    marginLeft: 'auto',
  },
  tableWrap: {
    '& .MuiPaper-elevation2': {
      boxShadow: 'none',
    },
  },
}))

export default ({ anomaly, data }) => {
  const classes = useStyles()
  const { closeDrawerAnomaly } = useUIActions()
  const {
    state: { ui: uiState },
  } = useStateContext()
  const [tableData, setTableData] = useState(null)
  const massageData = (data) => {
    const formattedData = data.reduce((acc, current) => {
      const lastCheckedIn = moment.unix(safeGet('eventTs', current, ''))

      acc.push({
        name: safeGet('name', current, ''),
        activatedOn: safeGet('activatedOn', current, ''),
        latitude: safeGet('coordinate.latitude', current, ''),
        longitude: safeGet('coordinate.longitude', current, ''),
        battery: safeGet('battery', current, ''),
        rssi: safeGet('rssi', current, ''),
        nextToken: safeGet('nextToken', current, ''),
        assetMovement: safeGet('assetMovement', current, ''),
        customer: safeGet('customerId', current, ''),
        deactivatedOn: safeGet('deactivatedOn', current, ''),
        description: safeGet('description', current, ''),
        lastCheckedIn: lastCheckedIn.format('M/D/YY hh:mm:ss'),
        firstCheckedIn: moment
          .unix(safeGet('firstCheckedIn', current, ''))
          .format('M/D/YY hh:mm:ss'),
        id: safeGet('id', current, ''),
        project: safeGet('projectId', current, ''),
        subType: safeGet('subType.name', current, ''),
        type: safeGet('type.name', current, ''),
        uuid: safeGet('uuid', current, ''),
        offlineFor: lastCheckedIn.fromNow(),
        deviceNumber: safeGet('deviceNumber', current, ''),
        unitNumber: safeGet('unitNumber', current, ''),
      })

      return acc
    }, [])
    return formattedData
  }
  useEffect(() => {
    if (anomaly === null) setTableData(null)

    if (anomaly)
      setTableData(massageData(data.sort((a, b) => b.eventTs - a.eventTs)))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Drawer
      className={classes.root}
      anchor='bottom'
      open={uiState.drawerAnomalyOpen}
      onClose={closeDrawerAnomaly}
    >
      <Container fixed>
        <Grid2 container spacing={0}>
          <Grid2 container  direction='row' justify='space-between'>
            <Grid2 >
              <Typography className={classes.title}>
                {tableData && `${tableData.length}`} ASSETS {`${anomaly}`}
              </Typography>
            </Grid2>
            <Grid2  className={classes.closeButton}>
              <IconButton
                data-test='closeDrawerButton'
                onClick={closeDrawerAnomaly}
                color='inherit'
              >
                <SystemIcon name='icn_x' color='secondary' />
              </IconButton>
            </Grid2>
          </Grid2>
          <Grid2  xs={12} className={classes.tableWrap}>
            {tableData && (
              <MaterialReactTable
                // icons={tableIcons}
                // options={{
                //   search: false,
                //   toolbarButtonAlignment: 'left',
                //   showTitle: false,
                //   exportButton: true,
                //   exportAllData: true,
                //   headerStyle: {
                //     minHeight: '48px',
                //   },
                // }}
                columns={[
                  { header: 'UnitId', accessorKey: 'unitNumber' },
                  { header: 'Type', accessorKey: 'type' },
                  { header: 'Subtype', accessorKey: 'subType' },
                  {
                    header: 'Time Since Last Checkin',
                    accessorKey: 'offlineFor',
                    cellStyle: { minWidth: '90px' },
                  },
                  {
                    header: 'Last Checked Date Time',
                    accessorKey: 'lastCheckedIn',
                    cellStyle: { minWidth: '90px' },
                  },
                  {
                    header: 'Initial Checkin',
                    accessorKey: 'firstCheckedIn',
                    cellStyle: { minWidth: '90px' },
                  },
                  {
                    header: 'UUID',
                    accessorKey: 'uuid',
                    cellStyle: { minWidth: '136px' },
                  },
                  { header: 'Lat', accessorKey: 'latitude' },
                  { header: 'Long', accessorKey: 'longitude' },
                  { header: 'ProjectId', accessorKey: 'project' },
                ]}
                data={tableData}
              />
            )}
          </Grid2>
        </Grid2>
      </Container>
      <div className={classes.drawerContentWrap} />
    </Drawer>
  )
}
