import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import {
  Grid2,
  Divider,
  Typography,
  Button,
  IconButton,
  CircularProgress,
} from '@mui/material'
import { makeStyles } from '@mui/styles'

import BatteryStatus from './BatteryStatus.js'
import ConnectionStatus from './ConnectionStatus.js'
import RetireAssetDialog from '../dialogs/RetireAssetDialog.js'
import { useUIActions } from '../../store/UI/UIActions.js'
import { formatDate, formatTime, safeGet, isAssetConnected } from '../../util.js'
import SystemIcon from './SystemIcon.js'
import EditAssetDialog from '../dialogs/EditAssetDialog.js'
import { useAuthActions } from '../../store/Auth/AuthActions.js'

/* eslint-disable quote-props */
const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: 'nowrap',
  },
  circularProgress: {
    marginTop: theme.spacing(4),
  },
  detailsHeader: {
    marginBottom: theme.spacing(0.5),
  },
  deviceStatus: {
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginBottom: 4,
    },
  },
  subtitle: {
    color: theme.colors.portlandCement,
  },
  detailsBody: {
    marginTop: theme.spacing(0.5),
  },
  batteryStatusContainer: {
    textAlign: 'right',
    marginLeft: 'auto',
    flexBasis: 0,
    minWidth: 71,
  },
  dataWrap: {
    marginTop: theme.spacing(0.5),
  },
  data: {
    padding: 0,
  },
  label: {
    color: theme.colors.portlandCement,
    textAlign: 'right',
    paddingRight: 2,
  },
  datum: {
    color: theme.colors.portlandCement,
    textAlign: 'left',
    paddingLeft: 2,
    overflow: 'hidden',
    '& p': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  tabs: {
    width: '100%',
  },
  eventList: {
    position: 'absolute',
  },
  retireButtonWrap: {
    alignSelf: 'flex-end',
  },
}))
/* eslint-enable quote-props */

const AssetDetails = ({ asset, setAsset }) => {
  const classes = useStyles()
  const { memberOf } = useAuthActions()
  const { enqueueSnackbar } = useSnackbar()

  const [assetData, setAssetData] = useState([])
  const { openRetireAssetDialog, openEditAssetDialog } = useUIActions()

  useEffect(() => {
    if (asset) {
      setAssetData([
        { key: 'SUPPLIER', value: safeGet('supplier.name', asset) },
        { key: 'DIMENSIONS', value: safeGet('dimensions.name', asset) },
        { key: 'DESCRIPTION', value: safeGet('description', asset) },
        {
          key: 'LAST CHECK IN',
          value: formatDate(safeGet('eventTs', asset)),
        },
        { key: 'VERSION', value: safeGet('version', asset) },
        { key: 'UUID', value: safeGet('uuid', asset) },
        {
          key: 'ACTIVATED ON',
          value: formatDate(safeGet('activatedOn', asset)),
        },
        { key: 'MAKE', value: safeGet('make', asset) },
        { key: 'MODEL', value: safeGet('model', asset) },
        { key: 'YEAR', value: safeGet('year', asset) },
        { key: 'CAPACITY', value: safeGet('capacity', asset) },
        { key: 'FORM', value: safeGet('form', asset) },
        {
          key: 'ANNUAL INSPECTION DATE',
          value: safeGet('annualInspection', asset),
        },
        {
          key: 'LAST REPORT',
          value: `${formatDate(safeGet('receivedTs', asset))} ${formatTime(safeGet('receivedTs', asset))}`,
        },
      ])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset])

  return (
    <>
      <Grid2 container direction='column' spacing={1} className={classes.root}>
        {!asset && (
          <Grid2 container justify='center' className={classes.circularProgress}>
            <CircularProgress color='primary' variant='indeterminate' />
          </Grid2>
        )}
        {asset && (
          <>
            <Grid2
              container
              direction='row'
              justify='space-between'
              spacing={1}
              className={classes.detailsHeader}
            >
              <Grid2>
                <Typography variant='h6' color='secondary'>
                  {safeGet('unitNumber', asset, '')}
                </Typography>
              </Grid2>

              {asset && asset.status !== 'retired' && (
                <>
                  {!asset.speed && (
                    <Grid2 className={classes.deviceStatus}>
                      <BatteryStatus good={safeGet('battery', asset)} />
                    </Grid2>
                  )}
                  <Grid2 className={classes.deviceStatus}>
                    <ConnectionStatus connected={isAssetConnected(asset)} />
                  </Grid2>
                </>
              )}
              <></>
              {asset && asset.status === 'retired' && (
                <Grid2 className={classes.deviceStatus}>
                  <Typography variant='body1' color='primary'>
                    RETIRED
                  </Typography>
                </Grid2>
              )}
              <></>
            </Grid2>
            <Grid2
              container
              direction='row'
              justify='space-between'
              className={classes.detailsBody}
            >
              <Grid2>
                <Typography color='secondary'>
                  {safeGet('type.name', asset, '').toUpperCase()}
                </Typography>
                <Typography color='secondary'>
                  {safeGet('subType.name', asset, '').toUpperCase()}
                </Typography>
              </Grid2>
              <Grid2 >
                {memberOf(/admins|customerAdmin[\S]+/) &&
                  asset &&
                  asset.status !== 'retired' && (
                    <IconButton onClick={openEditAssetDialog} color='inherit'>
                      <SystemIcon name='icn_pencil' color='secondary' />
                    </IconButton>
                  )}
              </Grid2>
            </Grid2>
            <Divider orientation='horizontal' />
            <Grid2  className={classes.dataWrap}>
              {assetData.map((d) => {
                return (
                  <Grid2
                    container
                    direction='row'
                    justify='center'
                    className={classes.data}
                    key={d.key}
                  >
                    <Grid2  className={classes.label}>
                      <Typography>{d.key}:</Typography>
                    </Grid2>
                    <Grid2  className={classes.datum}>
                      <Typography>{d.value}</Typography>
                    </Grid2>
                  </Grid2>
                )
              })}
            </Grid2>
            <Grid2 container direction='column' spacing={1}>
              {asset && asset.status !== 'retired' && (
                <Grid2 className={classes.retireButtonWrap}>
                  <Button
                    size='small'
                    color='secondary'
                    variant='outlined'
                    onClick={openRetireAssetDialog}
                  >
                    Retire Asset
                  </Button>
                </Grid2>
              )}
              {asset && asset.notes && (
                <Grid2 xs={12}>
                  <Typography>
                    <span className={classes.label}>NOTES: </span>
                    {asset.notes}
                  </Typography>
                </Grid2>
              )}
            </Grid2>
          </>
        )}
      </Grid2>
      <RetireAssetDialog
        onSubmit={(updatedAsset) => {
          setAsset(updatedAsset)
          enqueueSnackbar('Successfully retired asset.', {
            variant: 'success',
          })
        }}
        asset={asset}
      />
      <EditAssetDialog
        onSubmit={(updatedAsset) => {
          setAsset(updatedAsset)
          enqueueSnackbar('Successfully updated asset.', {
            variant: 'success',
          })
        }}
        asset={asset}
      />
    </>
  )
}

export default AssetDetails
