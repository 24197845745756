import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  FormControl,
  TextField,
  Typography,
  FormHelperText
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import SpinnerButton from '../elements/SpinnerButton.js'
import { useForm } from '../../hooks/Forms.js'
import { useAuthActions } from '../../store/Auth/AuthActions.js'

const useStyles = makeStyles((theme) => ({
  errorMsg: {
    color: theme.palette.error.main,
  },
}))

const ChangePasswordDialog = ({ closeForm, onSave = () => { }, open }) => {
  const classes = useStyles()

  const {
    clearFormState,
    formState,
    getFieldError,
    handleInputChange,
    handleSubmit,
    isValid,
  } = useForm({
    oldPassword: 'required',
    password: 'required|password',
    confirmPassword: 'matches:password',
  })

  const { changePassword } = useAuthActions()

  const handleCancelForm = () => {
    closeForm()
    clearFormState()
  }

  const onSubmit = async (fields) => {
    await changePassword(fields).then(() => {
      closeForm()
      clearFormState()
      onSave()
    })
  }

  return (
    <Dialog fullWidth onClose={closeForm} open={open}>
      <form onSubmit={(e) => handleSubmit(onSubmit, e)}>
        <DialogTitle>CHANGE PASSWORD</DialogTitle>
        <DialogContent>
          <Grid2 container spacing={2}>
            {formState.error && (
              <Grid2 className={classes.verticalGutters}>
                <Typography
                  variant='body1'
                  color='error'
                  data-test='formErrorMessage'
                >
                  {formState.error}
                </Typography>
              </Grid2>
            )}
            <Grid2 xs={12}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  type='password'
                  onChange={handleInputChange('oldPassword')}
                  error={!!getFieldError('oldPassword')}
                  label='PASSWORD'
                  variant='outlined'
                />
                <FormHelperText className={classes.errorMsg}>
                  {getFieldError('oldPassword') &&
                    getFieldError('oldPassword').pop()}
                </FormHelperText>
              </FormControl>
            </Grid2>
            <Grid2 xs={12}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  type='password'
                  onChange={handleInputChange('password')}
                  error={!!getFieldError('password')}
                  label='NEW PASSWORD'
                  variant='outlined'
                />
                <FormHelperText className={classes.errorMsg}>
                  {getFieldError('password') && getFieldError('password').pop()}
                </FormHelperText>
              </FormControl>
            </Grid2>
            <Grid2 xs={12}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  type='password'
                  onChange={handleInputChange('confirmPassword')}
                  error={!!getFieldError('confirmPassword')}
                  label='CONFIRM PASSWORD'
                  variant='outlined'
                />
                <FormHelperText className={classes.errorMsg}>
                  {getFieldError('confirmPassword') &&
                    getFieldError('confirmPassword').pop()}
                </FormHelperText>
              </FormControl>
            </Grid2>
          </Grid2>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' onClick={handleCancelForm}>
            Cancel
          </Button>
          <SpinnerButton
            loading={formState.loading}
            type='submit'
            valid={isValid()}
          >
            Submit
          </SpinnerButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ChangePasswordDialog
