import React, { useState, useEffect } from 'react'
import { Popover, Grid2, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import uniqBy from 'lodash/uniqBy.js'

import CheckboxTree from './CheckboxTree.js'
import { sortBy, synchronous, safeGet } from '../../util.js'
import {
  canceledPromiseErrorName,
  useCancellablePromise,
} from '../../promiseUtils.js'
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-root': {
      overflow: 'hidden',
      backgroundColor: theme.colors.backgroundColor,
      border: '1px solid white',
      borderRadius: 10,
      padding: 2,
    },
  },
  filterContainer: {

  },
  textFieldWrap: {
    width: '100%',
    paddingRight: '10px !important',
    paddingLeft: '10px !important',

    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    // marginRight: theme.spacing(1),
    // marginLeft: theme.spacing(1),
    // backgroundColor: 'blue'
  },
  filterTextField: {
    '& .MuiInputBase-input': {
      color: 'white',
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(1)})`,
      // transition: theme.transitions.create('width'),

    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.colors.lightSteel,
        borderRadius: 5
      },
    },
    '& .MuiInputBase-input::placeholder': {
      color: theme.colors.lightSteel,
      fontWeight: 'bold'
    },
  },
  formGroup: {
    maxHeight: theme.spacing(26),
    padding: theme.spacing(1),
    overflowY: 'auto',
    overflowX: 'hidden',
    flexWrap: 'unset',
  },
  treeContainer: {
    maxHeight: theme.spacing(50),
    overflow: 'auto',
  },
}))

const MapFilterMenu = ({
  open,
  onClose,
  onChange,
  getData,
  setData = () => { },
  anchor,
  // filters,
  // setAnomalyFilter,
  defaultChecked,
}) => {

  const classes = useStyles()
  const { cancellablePromise } = useCancellablePromise()
  const [anchorEl, setAnchorEl] = useState(null)
  const [list, setList] = useState([])
  const [textFilter, setTextFilter] = useState('')
  const [checkFilter, setCheckFilter] = useState([])
  const [treeDefaultChecked, setTreeDefaultChecked] = useState(false)

  const getSelectedIds = (list) => {
    let output = []

    for (let node of list) {
      if (node.selected) {
        output = [...output, node.id]
      }

      if (node.children) {
        output = [...output, ...getSelectedIds(node.children)]
      }
    }

    return output
  }

  const restoreFilters = (currentList, currentFilters) => {
    if (!(currentList instanceof Array)) {
      return currentList
    }

    return currentList.map((item) => {
      return {
        ...item,
        children: restoreFilters(safeGet('children', item), currentFilters),
        selected: currentFilters.indexOf(safeGet('id', item)) > -1,
      }
    })
  }

  useEffect(() => {
    if (anchor) {
      setAnchorEl(anchor.current)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchor])

  useEffect(() => {
    if (!getData) {
      return
    }

    cancellablePromise(synchronous(getData))
      .then((results) => {
        // alphabetize by name
        results = sortBy('name', results)

        // remove duplicates
        results = uniqBy(results, 'name')

        // keep checks after unmount
        results = restoreFilters(results, checkFilter)

        setList(results)

        // initialize the checklist by "checking" everything
        if (list.length !== results.length && defaultChecked) {
          setCheckFilter(results.map((d) => d.id))
          setTreeDefaultChecked(true)
        }
      })
      .catch((error) => {
        if (error.name === canceledPromiseErrorName) {
          return
        }
        console.error(error)
      })

    setTreeDefaultChecked(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData])

  useEffect(() => {
    onChange({
      checkFilter,
      textFilter,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkFilter, textFilter])

  return (
    <Popover
      className={classes.root}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Grid2 container direction='column' spacing={1} className={classes.filterContainer}>
        <Grid2 className={classes.textFieldWrap}>
          <TextField
            fullWidth
            autoFocus
            // margin='dense'
            // label='FILTER'
            placeholder='FILTER'
            value={textFilter}
            onChange={(e) => setTextFilter(e.target.value)}
            // variant='outlined'
            variant='outlined'
            className={classes.filterTextField}

          />
        </Grid2>
        <Grid2 className={classes.treeContainer}>
          <CheckboxTree
            selected={checkFilter}
            onChange={async (list) => setCheckFilter(getSelectedIds(list))}
            setSelected={setCheckFilter}
            structure={list}
            setStructure={setData}
            showActions
            defaultChecked={treeDefaultChecked}
          />
        </Grid2>
      </Grid2>
    </Popover>
  )
}

export default MapFilterMenu
