import React from 'react'
import ReactDOM from 'react-dom/client'
import { Amplify } from 'aws-amplify'
import { SnackbarProvider } from 'notistack'

import { StateProvider } from './store/stateContext.js'
import { ThemeProvider } from '@mui/material'

import awsmobile from './aws-exports.js'
import * as serviceWorker from './serviceWorker.js'
import App from './App.js'
import './App.scss'
import { theme } from './theme.js'

/* MAP STYLES */
import 'mapbox-gl/dist/mapbox-gl.css'
/* BASE STYLES */
import './index.css'

Amplify.configure(awsmobile)

document.addEventListener('DOMContentLoaded', () => {

  const rootElement = document.getElementById('root');

  if (rootElement) {
    const root = ReactDOM.createRoot(rootElement)

    root.render(
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <StateProvider>
            <App />
          </StateProvider>
        </SnackbarProvider>
      </ThemeProvider>
    )
  } else {
    console.error('Container not found')
  }


})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// support path prefixed with #! for use with S3 redirection
if (window && window.location.hash.indexOf('#!') === 0) {
  window.history.pushState({}, 'entry page', window.location.hash.substring(3))
}
