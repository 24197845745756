import React from 'react'

import Icons from '../../assets/icons/symbol-defs.svg'
import { SvgIcon } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  svg: {
    fill: theme.colors.asseTracOrange,
  },
}))

const BatteryStatus = ({ good }) => {
  const classes = useStyles()

  return (
    !good && (
      <SvgIcon>
        <svg className={classes.svg}>
          <use xlinkHref={Icons + '#icn_battery_alert'} />
        </svg>
      </SvgIcon>
    )
  )
}

export default BatteryStatus
