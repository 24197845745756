import React from 'react'
import { CircularProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  loadingOverlay: {
    position: 'fixed',
    zIndex: '1000',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    background: 'rgba(0,0,0,0.5)',
  },
  loadingIndicator: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    margin: '0 auto 0 -40px',
  },
  assetsLoadedCounter: {
    position: 'absolute',
    top: '50%',
    left: '0',
    right: '0',
    margin: '60px auto 0',
    fontWeight: 'bold',
    textAlign: 'center',
    color: 'white',
  },
}))

const LoadingOverlay = ({ loading, loadedAssetCount }) => {
  const classes = useStyles()

  return (
    <>
      {loading && (
        <div className={classes.loadingOverlay}>
          <div className={classes.loadingIndicator}>
            <CircularProgress size={80} />
          </div>
          {loadedAssetCount && (
            <div className={classes.assetsLoadedCounter}>
              Loaded data for {loadedAssetCount} assets...
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default LoadingOverlay
