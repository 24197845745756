import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import get from 'lodash/get.js'

import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Button,
  Divider,
  Fade,
  ListItemAvatar,
  Typography,
  CircularProgress,
  ListItemButton,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import SystemIcon from './SystemIcon.js'
import AssetIcon from './AssetIcon.js'

import { useStateContext } from '../../store/stateContext.js'
import { useUIActions } from '../../store/UI/UIActions.js'
import { useSearchActions } from '../../store/Search/SearchActions.js'
import { SET_SEARCH_RESULTS } from '../../store/Search/SearchReducer.js'

const useStyles = makeStyles((theme) => ({
  fade: {
    position: 'relative',
    /* eslint-disable no-dupe-keys */
    /* Firefox */
    width: ' -moz-calc(100% + 23px)',
    /* WebKit */
    width: '-webkit-calc(100% + 23px)',
    /* Opera */
    width: '-o-calc(100% + 23px)',
    /* Standard */
    width: 'calc(100% + 23px)',
    /* eslint-enable no-dupe-keys */

    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      right: 0,
      top: theme.spacing(6),
      bottom: 0,
      left: 0,
      width: 'auto',
    },
  },
  root: {
    marginLeft: theme.spacing(-3),
    height: '100vh',

    /* eslint-disable no-dupe-keys */
    /* Firefox */
    width: ' -moz-calc(100% + 48px)',
    /* WebKit */
    width: '-webkit-calc(100% + 48px)',
    /* Opera */
    width: '-o-calc(100% + 48px)',
    /* Standard */
    width: 'calc(100% + 48px)',
    /* eslint-enable no-dupe-keys */
    position: 'absolute',
    right: 0,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    '& .MuiDrawer-paperAnchorDockedRight': {
      width: '100%',
      position: 'relative',
      marginTop: -48,
      [theme.breakpoints.down('xs')]: {
        border: 'none',
      },
    },
  },
  list: {
    textAlign: 'center',
  },
  loading: {
    alignSelf: 'center',
    marginTop: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
  drawerContentWrap: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(5),
  },
  customerSelectorWrap: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  backdrop: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    touchAction: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1,
  },
}))

export default () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { closeDrawerSearch } = useUIActions()
  const { searchState } = useSearchActions()
  const [maxP, setMaxP] = useState(null)
  const [maxA, setMaxA] = useState(null)

  const {
    dispatch,
    state: { ui: uiState },
  } = useStateContext()

  const handleNavigation = (url) => {
    navigate(url)
    window.location.reload(false)
  }

  const handleSeeAll = (searchResults) => {
    dispatch({
      type: SET_SEARCH_RESULTS,
      payload: { searchResults },
    })
    navigate('/SearchResults')
  }

  // equalize the search results
  useEffect(() => {
    if (
      !searchState.quickSearchResults ||
      !searchState.quickSearchResults.projects ||
      !searchState.quickSearchResults.assets
    ) {
      return
    }

    const MAX = 8
    const p = searchState.quickSearchResults.projects
    const a = searchState.quickSearchResults.assets
    const total = p.length + a.length
    // total is less than max
    if (total === 0) {
      // 0
      setMaxP(0)
      setMaxA(0)
      return
    }
    // total is less than max
    if (total <= MAX) {
      // 1
      setMaxP(p.length)
      setMaxA(a.length)
      return
    }
    // both are over max
    if (p.length >= MAX && a.Length >= MAX) {
      // 2
      setMaxP(Math.floor(MAX / 2))
      setMaxA(Math.ceil(MAX / 2))
      return
    }
    // few projects many assets
    if (total > MAX && p.length <= Math.floor(MAX / 2)) {
      // 3
      setMaxP(p.length)
      setMaxA(MAX - p.length)
      return
    }
    // few assets many projects
    if (total > MAX && a.length <= Math.floor(MAX / 2)) {
      //4
      setMaxP(MAX - a.length)
      setMaxA(a.length)
      return
    }
    //
    if (total > MAX && p.length <= MAX) {
      // 5
      setMaxP(Math.floor(MAX / 2))
      setMaxA(Math.ceil(MAX / 2))
      return
    }
    //
    if (total > MAX && a.length <= MAX) {
      //6
      setMaxP(Math.floor(MAX / 2))
      setMaxA(Math.ceil(MAX / 2))
      return
    }
  }, [searchState])

  return (
    <Fade in={uiState.drawerSearchOpen} className={classes.fade}>
      <div>
        <Drawer
          open={uiState.drawerSearchOpen}
          onClose={closeDrawerSearch}
          anchor='right'
          variant='permanent'
          className={classes.root}
        >
          <div className={classes.drawerContentWrap}>
            {!searchState.quickSearchResults && (
              <CircularProgress
                className={classes.loading}
                color='primary'
                size={32}
                variant='indeterminate'
              />
            )}
            {searchState.quickSearchResults && (
              <>
                <List className={classes.list} onClick={closeDrawerSearch}>
                  {!maxP && !maxA && (
                    <Typography variant='body2'>no results</Typography>
                  )}
                  {searchState.quickSearchResults &&
                    searchState.quickSearchResults.projects &&
                    searchState.quickSearchResults.projects.length > 0 &&
                    searchState.quickSearchResults.projects
                      .slice(0, maxP)
                      .map((project) => (
                        <ListItem
                          key={project.id}
                          alignItems='flex-start'
                          onClick={() =>
                            handleNavigation(`/Projects/${project.id}`)
                          }
                        >
                          <ListItemButton>

                            <ListItemAvatar>
                              <SystemIcon name={'icn_project'} />
                            </ListItemAvatar>
                            <ListItemText
                              primary={`${project.name}`}
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    component='span'
                                    variant='body2'
                                    className={classes.inline}
                                    color='textPrimary'
                                  >
                                    {`${project.client}`}
                                  </Typography>
                                  {project.description &&
                                    ` - ${project.description}`}
                                </React.Fragment>
                              }
                            />
                          </ListItemButton>
                        </ListItem>
                      ))}
                  {searchState.quickSearchResults &&
                    searchState.quickSearchResults.projects &&
                    searchState.quickSearchResults.projects.length > maxP && (
                      <>
                        <Button
                          color='primary'
                          className={classes.button}
                          onClick={() => {
                            handleSeeAll(
                              searchState.quickSearchResults.projects,
                            )
                          }}
                        >
                          see all
                          {` ${searchState.quickSearchResults.projects.length} `}
                          projects
                        </Button>
                        <Divider variant='inset' component='li' />
                      </>
                    )}
                  {
                    // for resetting the colors in vsCode
                    <></>
                  }
                  {searchState.quickSearchResults &&
                    searchState.quickSearchResults.assets &&
                    searchState.quickSearchResults.assets.length > 0 &&
                    searchState.quickSearchResults.assets
                      .slice(0, maxA)
                      .map((asset) => (
                        <ListItem
                          key={asset.id}

                          alignItems='flex-start'
                          onClick={() =>
                            handleNavigation(`/Asset/${asset.id}`)
                          }
                        >
                          <ListItemButton>

                            <ListItemAvatar>
                              <AssetIcon name={get(asset, 'subType.iconName')} />
                            </ListItemAvatar>
                            <ListItemText
                              primary={`${asset.unitNumber}`}
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    component='span'
                                    variant='body2'
                                    className={classes.inline}
                                    color='textPrimary'
                                  >
                                    {get(asset, 'type.name') &&
                                      `${asset.type.name}`}
                                    {get(asset, 'subType.name') &&
                                      ` - ${asset.subType.name}`}
                                  </Typography>
                                  {asset.assetHistory &&
                                    asset.assetHistory.items &&
                                    asset.assetHistory.items[0] &&
                                    asset.assetHistory.items[0].coordinates &&
                                    ` | ${asset.assetHistory.items[0].coordinates}`}
                                </React.Fragment>
                              }
                            />
                          </ListItemButton>
                        </ListItem>
                      ))}
                  {searchState.quickSearchResults &&
                    searchState.quickSearchResults.assets &&
                    searchState.quickSearchResults.assets.length > maxA && (
                      <Button
                        color='primary'
                        className={classes.button}
                        onClick={() => {
                          handleSeeAll(searchState.quickSearchResults.assets)
                        }}
                      >
                        see all
                        {` ${searchState.quickSearchResults.assets.length} `}
                        assets
                      </Button>
                    )}
                </List>
              </>
            )}
          </div>
        </Drawer>
        <div className={classes.backdrop} onClick={closeDrawerSearch} />
      </div>
    </Fade>
  )
}
