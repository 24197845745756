import { useEffect, useRef } from 'react'

export const canceledPromiseErrorName = 'CANCELED_PROMISE'

function getCanceledError() {
  const error = new Error('promise was canceled')
  error.name = canceledPromiseErrorName
  return error
}

function makeCancelable(promise) {
  let isCanceled = false

  const wrappedPromise = new Promise((resolve, reject) => {
    promise
      .then((val) => (isCanceled ? reject(getCanceledError()) : resolve(val)))
      .catch((error) =>
        isCanceled ? reject(getCanceledError()) : reject(error),
      )
  })

  return {
    promise: wrappedPromise,
    cancel() {
      isCanceled = true
    },
  }
}

export function useCancellablePromise() {
  const promises = useRef()

  useEffect(() => {
    promises.current = promises.current || []
    return function cancel() {
      promises.current.forEach((p) => p.cancel())
      promises.current = []
    }
  }, [])

  function cancellablePromise(promise) {
    const cPromise = makeCancelable(promise)
    promises.current.push(cPromise)
    return cPromise.promise
  }

  return { cancellablePromise }
}
