import React, { useState, useEffect } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs/index.js'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid2,
  TextField,
  Typography,
} from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import ClientPicker from '../elements/ClientPicker.js'
import { useStateContext } from '../../store/stateContext.js'
import { useUIActions } from '../../store/UI/UIActions.js'
import { useProjectActions } from '../../store/Project/ProjectActions.js'
import { useForm } from '../../hooks/Forms.js'
import SpinnerButton from '../elements/SpinnerButton.js'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: theme.colors.backgroundColor
  },
  datepicker: {
    width: '100%',
    '& .MuiInputBase-root': {
      color: 'white',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.lightSteel,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.lightSteel,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.lightSteel,
    },
    '& .MuiInputBase-input::placeholder': {
      color: 'white',
      opacity: 1,
    },
    '& .MuiSvgIcon-root': {
      color: theme.colors.lightSteel,
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
  },
}))

const UpdateProjectDialog = ({ project, onSubmit }) => {
  const classes = useStyles()
  const theme = useTheme()
  project = project || {}

  const { closeUpdateProjectDialog } = useUIActions()
  const { updateProject } = useProjectActions()

  // app level state
  const [sending, setSending] = useState(false)
  const [projectNames, setProjectNames] = useState([])
  const { state } = useStateContext()
  const projectsState = state.projects

  // component level state
  const {
    inputs,
    setInputs,
    clearFormState,
    formState,
    getFieldError,
    getValue,
    handleInputChange,
    handleSubmit,
    isValid,
    validateForm,
  } = useForm({
    name: `required|unique:${projectNames.join(',')}`,
    client: 'required',
  })
  useEffect(() => {
    // Set up the validation rules
    // for the name using the projectList
    const names = projectsState.projectList.reduce((acc, cur) => {
      acc.push(cur.name)
      return acc
    }, [])
    if (project.name) {
      names.splice(names.indexOf(project.name), 1)
    }
    setProjectNames(names)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsState])

  // this const forces a validateForm when the
  // the ClientPicker finally loads
  const client = getValue('client')

  // update project name if changed
  useEffect(() => {
    setInputs({
      ...inputs,
      name: project.name,
      client: project.client,
      description: project.description,
      startDate: project.startDate,
      endDate: project.endDate,
    })
    validateForm()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    project.name,
    project.client,
    project.description,
    project.startDate,
    project.endDate,
  ])

  useEffect(() => {
    validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]) // force validateForm() when ClientPicker Loads

  // component level actions
  const clearForm = () => {
    setSending(false)
    clearFormState()
  }

  const cancelForm = () => {
    closeUpdateProjectDialog()
    clearForm()
  }

  /**
   * Submits the form
   * @param {object} form  Object containing form inputs
   */
  const submitForm = async (form) => {
    try {
      setSending(true)
      const updatedProject = await updateProject({
        ...form,
        id: project.id,
      })

      // call onSubmit prop
      if (typeof onSubmit === 'function') {
        onSubmit(updatedProject)
      }

      closeUpdateProjectDialog()
      clearForm()
    } catch (err) {
      setSending(false)
      throw err
    }
  }

  return (
    <Dialog
      fullWidth
      scroll='body'
      open={state.ui.updateProjectDialogOpen}
      onClose={closeUpdateProjectDialog}
    >
      <form onSubmit={(e) => handleSubmit(submitForm, e)} className={classes.dialog}>
        <DialogContent sx={{ color: 'white' }}>
          <DialogTitle align='center' >EDIT PROJECT</DialogTitle>
          {formState.error && (
            <Grid2 >
              <Typography variant='body1' color='error'>
                {formState.error}
              </Typography>
            </Grid2>
          )}
          <Grid2 xs={12}>
            <TextField
              autoComplete='off'
              autoFocus
              error={!!getFieldError('name')}
              helperText={getFieldError('name')}
              id='project-name'
              label='Project Name'
              fullWidth
              margin='normal'
              onChange={handleInputChange('name')}
              value={getValue('name', project.name)}
              variant='outlined'
              sx={theme.textfield}
            />
          </Grid2>
          <Grid2 xs={12}>
            <ClientPicker
              error={!!getFieldError('client')}
              helperText={getFieldError('client')}
              value={getValue('client', project.client)}
              onChange={handleInputChange('client')}
            />
          </Grid2>
          <Grid2 xs={12}>
            <TextField
              data-test='updateProjectDialogDesc'
              autoComplete='off'
              error={!!getFieldError('description')}
              fullWidth
              helperText={getFieldError('description')}
              id='project-description'
              label='Project Description'
              margin='normal'
              multiline
              onChange={handleInputChange('description')}
              maxRows='4'
              name='description'
              value={getValue('description', project.description)}
              variant='outlined'
              sx={theme.textfield}
            />
          </Grid2>
          <Grid2 container xs={12} spacing={2} sx={{marginTop: 2}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid2 xs={12} md={6}>
                <DatePicker
                  // autoOk
                  className={classes.datepicker}
                  // disableToolbar
                  format='MM/dd/yyyy'
                  id='start-date'
                  KeyboardButtonProps={{
                    'aria-label': 'start date',
                  }}
                  label='Start Date'
                  margin='normal'
                  name='startDate'
                  onChange={handleInputChange('startDate')}
                  value={getValue('startDate', project.startDate)}
                  // variant='inline'
                  data-test='createProjectDialogStartDate'
                />
              </Grid2>
              <Grid2 xs={12} md={6}>
                <DatePicker
                  autoOk
                  name='endDate'
                  className={classes.datepicker}
                  disableToolbar
                  format='MM/dd/yyyy'
                  id='end-date'
                  KeyboardButtonProps={{
                    'aria-label': 'end date',
                  }}
                  label='End Date'
                  margin='normal'
                  onChange={handleInputChange('endDate')}
                  value={getValue('endDate', project.endDate)}
                  variant='inline'
                  data-test='createProjectDialogEndDate'

                />
              </Grid2>
            </LocalizationProvider>
          </Grid2>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' onClick={cancelForm}>
            Cancel
          </Button>
          <SpinnerButton loading={sending} type='submit' valid={isValid()}>
            Submit
          </SpinnerButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default UpdateProjectDialog
