export const INIT_MAP = 'INIT_MAP'
export const CLEAR_MAP = 'CLEAR_MAP'
export const SET_VIEWPORT = 'SET_VIEWPORT'
export const UPDATE_CURRENT_MAPID = 'UPDATE_CURRENT_MAPID'
export const UPDATE_VIEWPORT = 'UPDATE_VIEWPORT'
export const SET_GEOJSONLAYERS = 'SET_GEOJSONLAYERS'
export const ADD_GEOJSONLAYERS = 'ADD_GEOJSONLAYERS'
export const REMOVE_GEOJSONLAYERS = 'REMOVE_GEOJSONLAYERS'
export const SET_ICONLAYERS = 'SET_ICONLAYERS'
export const SET_PATHLAYERS = 'SET_PATHLAYERS'
export const SET_WAYPOINTLAYERS = 'SET_WAYPOINTLAYERS'
export const SET_MAP_INST = 'SET_MAP_INST'
export const SET_TOOLTIP = 'SET_TOOLTIP'
export const SET_FILTERED_LAYERS = 'SET_FILTERED_LAYERS'

export const initialState = {
  config: {
    mapboxApiAccessToken: process.env.REACT_APP_MapboxAccessToken,
    mapStyle: 'mapbox://styles/mapbox/satellite-streets-v10',
  },
  viewport: {
    width: null,
    height: null,
    latitude: 37.2710663206884,
    longitude: -96.03521798738298,
    pitch: 0,
    zoom: 3.7054833014082855,
    bearing: 0,
  },

  geoJsonLayers: [],
  iconLayers: {},
  pathLayers: {},
  waypointLayers: {},
  filteredLayers: [],
  tooltip: null,
  currentMapId: null,
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT_MAP:
      return {
        ...initialState,
      }
    case CLEAR_MAP:
      return {
        ...initialState,
        config: {
          ...state.config,
        },
        viewport: {
          ...state.viewport,
        },
      }
    case SET_TOOLTIP:
      return {
        ...state,
        tooltip: payload.tooltip,
      }
    case SET_VIEWPORT:
      return {
        ...state,
        viewport: payload.viewport,
      }
    case UPDATE_CURRENT_MAPID:
      return {
        ...state,
        currentMapId: payload.id,
      }
    case UPDATE_VIEWPORT:
      return {
        ...state,
        viewport: {
          ...state.viewport,
          ...payload.viewport,
        },
      }
    case SET_ICONLAYERS:
      return {
        ...state,
        iconLayers: payload.iconLayers,
      }
    case SET_PATHLAYERS:
      return {
        ...state,
        pathLayers: payload.pathLayers,
        suppressIcon: payload.suppressIcon,
      }
    case SET_GEOJSONLAYERS:
      return {
        ...state,
        geoJsonLayers: payload.geoJsonLayers,
      }
    case ADD_GEOJSONLAYERS:
      return {
        ...state,
        geoJsonLayers: [...state.geoJsonLayers, payload.geoJsonLayer],
      }
    case REMOVE_GEOJSONLAYERS:
      return {
        ...state,
        geoJsonLayers: state.geoJsonLayers.filter(
          (layer) => layer !== payload.geoJsonLayer,
        ),
      }

    case SET_WAYPOINTLAYERS:
      return {
        ...state,
        waypointLayers: payload.waypointLayers,
      }
    case SET_FILTERED_LAYERS:
      //console.log(payload.filteredLayers)
      return {
        ...state,
        filteredLayers: payload.filteredLayers,
      }
    default:
      return state
  }
}
