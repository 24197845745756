import React from 'react'

import {
  Grid2,
  Typography,
  IconButton,
  SvgIcon,
  Hidden
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import KmlTree from '../elements/KmlTree.js'
import Icons from '../../assets/icons/symbol-defs.svg'

import { useUIActions } from '../../store/UI/UIActions.js'

const useStyles = makeStyles((theme) => ({
  addOverlayButton: {
    marginLeft: 'auto',
  },
  closeOverlayDrawerButton: {
    marginLeft: 'auto',
  },
  svg: {
    fill: theme.colors.asseTracOrange,
  },
}))

export default ({ project, onChange = () => { } }) => {
  const classes = useStyles()
  const { closeTempDrawerMapOverlays, openUploadOverlayDialog } = useUIActions()

  const renderOverlays = () => {
    if (!project || !project.overlays || project.overlays.length === 0) {
      return <span>No overlays</span>
    }

    return project.overlays.map((overlay, index) => (
      <KmlTree
        key={`kml-${index}`}
        s3Object={overlay.file}
        onSelect={onChange}
      />
    ))
  }

  return !project ? (
    <div>Project not found</div>
  ) : (
    <div>
      <Grid2 container direction='row' alignItems='center'>
        <Grid2>
          <IconButton onClick={openUploadOverlayDialog} >
            <SvgIcon>
              <svg className={classes.svg}>
                <use xlinkHref={Icons + '#icn_add'} />
              </svg>
            </SvgIcon>
          </IconButton>
        </Grid2>
        <Grid2>
          <Typography variant='h6' color='secondary'>MAP OVERLAYS</Typography>
        </Grid2>
        <Hidden mdUp>
          <Grid2 className={classes.closeOverlayDrawerButton}>
            <IconButton onClick={closeTempDrawerMapOverlays}>
              <SvgIcon color='secondary'>
                <svg>
                  <use xlinkHref={Icons + '#icn_x'} />
                </svg>
              </SvgIcon>
            </IconButton>
          </Grid2>
        </Hidden>
      </Grid2>
      <Grid2 container>
        <Grid2 className={classes.overlayListItem} xs={12}>
          {renderOverlays()}
        </Grid2>
      </Grid2>
    </div>
  )
}
