import React from 'react'
import { useForm } from '../../hooks/Forms.js'
import { useAuthActions } from '../../store/Auth/AuthActions.js'
import { Grid2, Button, TextField, Typography } from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import SpinnerButton from '../elements/SpinnerButton.js'

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    marginBottom: 8,
    marginTop: 8,
    maxWidth: theme.spacing(32),
  },
  verticalGutters: {
    marginBottom: 8,
    marginTop: 8,
  },
}))

export default () => {
  const classes = useStyles()
  const theme = useTheme()

  const { formState, getFieldError, handleSubmit, handleInputChange, isValid } =
    useForm({
      email: 'required|email',
    })

  const { recoverPassword, rememberedPassword } = useAuthActions()

  const handleBack = () => {
    rememberedPassword()
  }

  return (
    <>
      <form
        onSubmit={(e) => handleSubmit(recoverPassword, e)}
        data-test='forgotPasswordForm'
      >
        {formState.error && (
          <Grid2 className={classes.errorMessage}>
            <Typography
              variant='body1'
              color='error'
              data-test='formErrorMessage'
            >
              {formState.error}
            </Typography>
          </Grid2>
        )}
        <Grid2 className={classes.verticalGutters}>
          <TextField
            autoFocus
            fullWidth
            data-test='emailField'
            margin='normal'
            label='EMAIL'
            error={!!getFieldError('email')}
            onChange={handleInputChange('email')}
            variant='outlined'
            sx={theme.textfield}
          />
        </Grid2>
        <Grid2 align='center' className={classes.verticalGutters}>
          <SpinnerButton
            data-test='forgotPasswordSubmitButton'
            type='submit'
            valid={isValid()}
            loading={formState.loading}
          >
            Submit
          </SpinnerButton>
        </Grid2>
        <Grid2 align='center' className={classes.verticalGutters}>
          <Button onClick={handleBack}>Back</Button>
        </Grid2>
      </form>
    </>
  )
}
