import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { makeStyles } from '@mui/styles'


import AssetIcon from './AssetIcon.js'
import AssetIconMap from '../../assets/icons/asset-icon-map.json'

const useStyles = makeStyles((theme) => ({
  select: {
    minHeight: 56,
    '&>div': {
      paddingTop: 14,
      paddingBottom: 14,
    },
  },
  dropdownStyle: {
    width: theme.spacing(38),
    '& .MuiList-root': {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
}))

const AssetIconSelect = ({ onChange = () => { }, value = '' }) => {
  const classes = useStyles()
  const iconLabelRef = useRef()
  return (
    <FormControl fullWidth variant='outlined'>
      <InputLabel ref={iconLabelRef}>Icon</InputLabel>
      <Select
        className={classes.select}
        fullWidth
        // labelWidth={
        //   iconLabelRef.current ? iconLabelRef.current.offsetWidth : 31.25
        // }
        onChange={onChange}
        value={value}
        MenuProps={{ classes: { paper: classes.dropdownStyle } }}
      >
        <MenuItem value=''>None</MenuItem>

        {AssetIconMap.icons.map((icon) => (
          <MenuItem key={icon.key} value={icon.key}>
            <AssetIcon name={icon.key} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

AssetIconSelect.propTypes = {
  onChange: PropTypes.func,
}

export default AssetIconSelect
