/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react'
import { Button, Typography, DialogTitle, Dialog, DialogActions, DialogContent, Step, StepLabel, StepContent, Stepper } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useStateContext } from '../../../store/stateContext.js'
import { useUIActions } from '../../../store/UI/UIActions.js'
import { useProjectActions } from '../../../store/Project/ProjectActions.js'
import { useForm } from '../../../hooks/Forms.js'
import DefineProjectSection from './DefineProjectSection.js'
import SelectProjectBoundariesSection from './SelectProjectBoundariesSection.js'
import SpinnerButton from '../../elements/SpinnerButton.js'
import UploadMapOverlaySection from './UploadMapOverlaySection.js'
import Validator from '../../../libs/Validator.js'
const useStyles = makeStyles((theme) => ({
  stepperControls: {
    marginTop: theme.spacing(3),
  },
  dialogContent: {
    backgroundColor: theme.colors.backgroundColor
  },
  label: {
    '&.MuiStepLabel-root .MuiStepLabel-label': {
      color: 'white',
    },
  },

}))

const steps = [
  {
    label: 'Define Project',
    component: DefineProjectSection,
    validationRules: {
      client: 'required',
      description: '',
      // startDate: 'date',
      // endDate: 'date',
    },
  },
  {
    label: 'Upload Map Overlay',
    component: UploadMapOverlaySection,
    validationRules: {
      // kmlFile: 'required',
    },
  },
  {
    label: 'Select Project Boundaries',
    component: SelectProjectBoundariesSection,
    validationRules: {
      // boundaries: 'coordinates',
    },
  },
]

const NewProjectDialog = () => {
  const classes = useStyles()
  const { closeNewProjectDialog } = useUIActions()
  const { createProject, getProjectList } = useProjectActions()
  const [projectList] = useState([])
  // app level state
  const [sending, setSending] = useState(false)
  const { state } = useStateContext()
  //setProjectList(getProjectList())
  // component level state
  const [activeStep, setActiveStep] = useState(0)
  const formHook = useForm(
    // consolidate step validation rules
    steps.reduce(
      (acc, cur) => ({
        ...acc,
        ...cur.validationRules,
      }),
      {},
    ),
  )
  const { clearFormState, formState, handleSubmit, inputs, getValue, isValid } = formHook
  // component level actions
  /**
   * Goes to previous step in stepper
   * @param {number} index  Current step index
   */
  const goBack = (index) => {
    setActiveStep(index - 1)
  }

  /**
   * Goes to next step in stepper
   * @param {number} index  Current step index
   * @param {object} step   Current step properties
   */
  const goNext = (index, step) => {
    const validator = new Validator(step.validationRules)
    const errors = validator.validate(inputs)

    if (errors) {
      // Errors
      console.error(errors)
      return
    }

    setActiveStep(index + 1)
  }

  const clearForm = () => {
    setSending(false)
    setActiveStep(0)
    clearFormState()
  }

  const cancelForm = () => {
    closeNewProjectDialog()
    clearForm()
  }

  /**
   * Submits the form
   * @param {object} form  Object containing form inputs
   */
  const onSubmit = async (form) => {
    try {
      setSending(true)

      await createProject(form)

      closeNewProjectDialog()
      getProjectList()
      clearForm()
    } catch (err) {
      setSending(false)
      throw err
    }
  }

  useEffect(() => {
    // Set up the validation rules
    // for the name using the projectList
    const names = projectList.reduce((acc, cur) => {
      acc.push(cur.name)
      return acc
    }, [])

    const validationRules = steps[0].validationRules
    steps[0].validationRules = {
      ...validationRules,
      name: `required|unique:${names.join(',')}`,
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.projects.projectList])

  return (
    <Dialog
      fullWidth
      scroll='body'
      open={state.ui.newProjectDialogOpen}
      onClose={closeNewProjectDialog}
    >
      <DialogContent
        className={classes.dialogContent}
      >
        <DialogTitle sx={{ color: 'white' }}>NEW PROJECT</DialogTitle>
        {formState.error && (
          <Typography variant='body1' color='error'>
            {formState.error}
          </Typography>
        )}
        <form onSubmit={(e) => handleSubmit(onSubmit, e)} >
          <Stepper activeStep={activeStep} orientation='vertical'>
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel className={classes.label}>{step.label}</StepLabel>
                <StepContent>
                  <step.component {...formHook} />
                  {index < steps.length - 1 ? (
                    <div className={classes.stepperControls}>
                      <Button
                        onClick={() => goBack(index, step)}
                        disabled={index === 0}
                        data-test='createProjectDialogBackButton'
                        sx={{ marginRight: 2 }}
                        color='secondary'
                      >
                        Back
                      </Button>
                      <Button
                        color='primary'
                        variant='contained'
                        onClick={() => goNext(index, step)}
                        data-test='createProjectDialogNextButton'
                        sx={{ borderRadius: 5, paddingX: 5 }}

                      >
                        Next
                      </Button>
                    </div>
                  ) : (
                    <div className={classes.stepperControls}>
                      <Button
                        onClick={() => goBack(index, step)}
                        disabled={index === 0}
                        sx={{ marginRight: 2 }}
                        color='secondary'
                      >
                        Back
                      </Button>
                      <SpinnerButton
                        loading={sending}
                        onClick={() => { }}
                        type='submit'
                        valid={isValid()}
                        data-test='createProjectDialogSubmitButton'
                        sx={{ borderRadius: 5, paddingX: 5 }}
                      >
                        Submit
                      </SpinnerButton>
                    </div>
                  )}
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </form>
      </DialogContent>
      <DialogActions
        className={classes.dialogContent}
      >
        <Button color='secondary' onClick={cancelForm}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NewProjectDialog
