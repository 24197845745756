import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Fuse from 'fuse.js'
import { Drawer, Grid2 } from '@mui/material'
import { makeStyles } from '@mui/styles'
import AnomalyReportsButton from '../elements/AnomalyReportsButton.js'
import AnomalyDrawer from '../elements/AnomalyDrawer.js'
import AssetInfo from '../elements/AssetInfo.js'
import AsseTracAppBar from '../elements/AsseTracAppBar.js'
import AsseTracMap from '../elements/AsseTracMap.js'
import LoadingOverlay from '../elements/LoadingOverlay.js'
import MapToolbar from '../elements/MapToolbar.js'
import ProjectTooltip from '../elements/ProjectTooltip.js'
// import AssetToolTip from '../elements/AssetTooltip.js'

import { useStateContext } from '../../store/stateContext.js'
import { useAssetsActions } from '../../store/Assets/AssetsActions.js'
import { useMapActions } from '../../store/Map/MapActions.js'
import { useProjectActions } from '../../store/Project/ProjectActions.js'
import { useUIActions } from '../../store/UI/UIActions.js'
import { SET_ICONLAYERS, SET_PATHLAYERS } from '../../store/Map/MapReducer.js'

import { filterBy, isAssetConnected, safeGet, formatDateTime } from '../../util.js'

const ASSET_SEARCH_OPTS = {
  keys: [
    {
      name: 'uuid',
      weight: 0.5,
    },
    {
      name: 'name',
      weight: 0.2,
    },
    {
      name: 'type.name',
      weight: 0.2,
    },
    {
      name: 'description',
      weight: 0.1,
    },
  ],
}

const PROJECT_SEARCH_OPTS = {
  keys: [
    {
      name: 'id',
      weight: 0.5,
    },
    {
      name: 'name',
      weight: 0.3,
    },
    {
      name: 'description',
      weight: 0.2,
    },
  ],
}

const useStyles = makeStyles((theme) => ({
  pageWrap: {
    // height: '100%',
    height: '100vh',
    // overflow: 'hidden',
  },
  toolbarWrap: {
    width: '100%',
  },
  toolbar: {
    width: '100%',
    boxSizing: 'border-box',
    borderBottomColor: theme.colors.lightSteel,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  toolbarIconButton: {
    padding: 8,
  },
  openFavoritesDrawerButton: {
    marginLeft: 'auto',
  },
}))

const DashboardPage = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  const { state, dispatch } = useStateContext()

  const { getAssets, getAssetTypes } = useAssetsActions()
  const { clearLayers /*, visibleOnMap*/, zoomToCoordinates } = useMapActions()
  const { getProjectList } = useProjectActions()
  const { closeAssetInfo } = useUIActions()

  const [projectList, setProjectList] = useState([])
  const [projectsFilter, setProjectsFilter] = useState([])
  const [filteredProjectList, setFilteredProjectList] = useState([])
  const [assets, setAssets] = useState([])
  const [assetTypes, setAssetTypes] = useState([])
  const [assetsFilter, setAssetsFilter] = useState([])
  const [anomalyFilter, setAnomalyFilter] = useState(null)
  const [filteredAssets, setFilteredAssets] = useState([])
  //const [assetHistoryList, setAssetHistoryList] = useState([])

  useEffect(() => {
    // on mount reset
    clearLayers()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Update the asset icons on the asset markers
  useEffect(() => {
    const iconLayers = {}

    for (let asset of filteredAssets) {
      const coordinates = asset.coordinate
      if (!coordinates) {
        continue
      }

      iconLayers[asset.id] = {
        id: asset.id,
        icon: safeGet('subType.iconName', asset),
        unitNumber: safeGet('unitNumber', asset),
        typeName: safeGet('type.name', asset),
        subTypeName: safeGet('subType.name', asset),
        lastReceived: formatDateTime(
          safeGet('receivedTs', asset),
          'M/D/YY h:mmA',
        ),
        coordinates,
        onClick(info) {
          const id = safeGet('object.properties.id', info)
          navigate(`/Asset/${id}`)
        },
      }
    }

    dispatch({
      type: SET_ICONLAYERS,
      payload: {
        iconLayers,
      },
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredAssets])

  useEffect(() => {
    setAssets(getAssets(false))
    setAssetTypes(getAssetTypes())
    setProjectList(getProjectList())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.assets.assetList,
    state.assets.assetTypes,
    state.assets.projectAssetList,
    state.projects.projectList,
  ])

  useEffect(() => {
    let bounds = []

    const pathLayers = {}
    for (let project of filteredProjectList) {
      if (!project.boundaries) {
        // no boundaries to show
        continue
      }

      bounds = [...bounds, ...project.boundaries]

      pathLayers[project.id] = {
        id: project.id,
        boundaries: project.boundaries,
        name: project.name,
        client: project.client,
        onClick(info) {
          navigate(`/Projects/${info.object.id}`)
        },
      }
    }

    zoomToCoordinates(bounds)

    dispatch({
      type: SET_PATHLAYERS,
      payload: {
        pathLayers,
      },
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredProjectList])

  // Filter Assets
  useEffect(() => {
    let results = assets.filter((asset) => asset.coordinate)

    // filter by checkboxes
    results = filterBy({
      list: results,
      filters: assetsFilter.checkFilter,
      props: ['type.name', 'subType.name'],
      override: (asset) =>
        !asset.type && assetsFilter.checkFilter.indexOf('') > -1,
    })

    // filter by text field
    if (assetsFilter.textFilter) {
      const fuse = new Fuse(results, ASSET_SEARCH_OPTS)

      results = fuse.search(assetsFilter.textFilter)
    }

    // filter by anomaly
    if (anomalyFilter) {
      if (anomalyFilter === 'Low Battery') {
        results = filterBy({
          list: results,
          filters: [0],
          props: ['battery'],
        })
      }

      if (anomalyFilter === 'Lost Connection') {
        results = results.filter((asset) => !isAssetConnected(asset))
      }
    }

    // filter by viewport
    // results = results.filter(asset => visibleOnMap(asset.coordinate)) // Disabled due to unreliability of map bounds

    setFilteredAssets(results)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anomalyFilter, assets, assetsFilter, assetTypes, state.map.viewport])

  // Filter Projects
  useEffect(() => {
    let results = projectList

    // Filter out the inactive projects
    // results = filterBy({
    //   list: results,
    //   filters: 'active',
    //   props: ['status'],
    // })

    // Filter out the projects by the gui check filter
    results = filterBy({
      list: results,
      filters: projectsFilter.checkFilter,
      props: ['id'],
    })

    if (projectsFilter.textFilter) {
      const fuse = new Fuse(results, PROJECT_SEARCH_OPTS)

      results = fuse.search(projectsFilter.textFilter)
    }

    setFilteredProjectList(results)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectList, projectsFilter])

  const onMapInitialize = () => {
    //  getActiveCustomer()
    //    .then(function (customer) {
    //     setAssetHistoryList(getAssetsHistory())
    //     setAssetTypes(getAssetTypes())
    //     setProjectList(getProjectList())
    //    })
    //   .then(function (customer) {
    //     if (customer)
    //       setProjectList(customer.projects.items)
    //   }).catch(console.error)
    //  getAssets()
    //    .then(setAssets)
    //    .catch(console.error)
    // getProjectList()
    //   .then(setProjectList)
    //   .catch(console.error)
    // getAssetTypes()
    //   .then(setAssetTypes)
    //   .catch(console.error)
    // dispatch({
    //   type: SET_TOOLTIP,
    //   payload: {},
    // })
  }

  return (
    <Grid2 container direction='column' className={classes.pageWrap}>
      <AsseTracAppBar title='MAP' />
      <Grid2 container direction='column' sx={{ flexGrow: 1 }}>
        <MapToolbar
          projectsFilter={projectsFilter}
          setProjectsFilter={setProjectsFilter}
          projectList={projectList}
          setProjectList={setProjectList}
          assetsFilter={assetsFilter}
          setAssetsFilter={setAssetsFilter}
          setAnomalyFilter={setAnomalyFilter}
          anomalyFilter={anomalyFilter}
        />{' '}
        <Grid2 sx={{ flexGrow: 1 }} >
          <AsseTracMap onInitialize={onMapInitialize} />{' '}
          <ProjectTooltip tooltip={state.map.tooltip} />{' '}
          {/* <AssetToolTip tooltip={state.map.tooltip} />{' '} */}
          {anomalyFilter && <AnomalyReportsButton />}{' '}
        </Grid2>{' '}
        <LoadingOverlay loading={state.assets.loading} />
      </Grid2>{' '}
      <Drawer
        anchor='right'
        open={state.ui.assetInfoOpen}
        onClose={closeAssetInfo}
      >
        <AssetInfo assets={state.assets.selectedAssets} />{' '}
      </Drawer>{' '}
      <AnomalyDrawer anomaly={anomalyFilter} data={filteredAssets} />{' '}
    </Grid2>
  )
}

export default DashboardPage
