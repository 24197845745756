import { IconLayer, PathLayer } from '@deck.gl/layers'
import { CompositeLayer } from '@deck.gl/core'
import { convertCoordinatesToTuples } from '../util.js'
import { safeGet } from '../util.js'

import ProjectPin from '../assets/img/svg/project_pin.svg'

export default class BoundaryLayer extends CompositeLayer {
  renderLayers() {
    return [
    new PathLayer({
        id: `${this.id}-path`,
        data: this.props.data,
        pickable: true,
        widthScale: 20,
        widthMinPixels: 3,
        widthMaxPixels: 3,
        getPath: (d) => convertCoordinatesToTuples(d.boundaries),
        getColor: [255, 255, 255],
        getWidth: 0.75,
      }),
      /* eslint-disable */
      this.props.suppressIcon
        ? null
        : new IconLayer({
          id: `${this.id}-icon`,
          data: this.props.data,
          pickable: true,
          widthScale: 20,
          widthMinPixels: 2,
          getIcon: (d) => ({
            url: ProjectPin,
            width: 40,
            height: 49,
            anchorY: 49,
          }),
          getPosition: (d) => {
            return [d.boundaries[0].longitude, d.boundaries[0].latitude, 0]
          },
          getSize: 50,
          onClick(info) {
            if (typeof info.object.onClick === 'function') {
              info.object.onClick(info)
            }
          },
        }),
      /* eslint-enable */
    ]
  }

  getPickingInfo({ info, mode, sourceLayer }) {

    switch (mode) {
      case 'hover':
        { // kill tooltip when hovering the boundary lines
          if (sourceLayer.id.endsWith('-path')) {
            break
          }
          const onTooltip = safeGet('layer.props.onTooltip', info) || (() => { })

          onTooltip(info.object ? { ...info.object, x: info.x, y: info.y } : null)
          break
        }
      case 'query':
        if (typeof sourceLayer.props.onClick === 'function') {
          sourceLayer.props.onClick(info)
        }
        break
      default:
        break
    }

    return info
  }
}

BoundaryLayer.layerName = 'BoundaryLayer'
