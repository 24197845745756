import { useState } from 'react'
import Validator from '../libs/Validator.js'

/**
 * Custom hook for handling form input and submission
 * @param {object} validationRules  Object with keys being the name of input and values being the rule in a string
 */
export const useForm = (validationRules) => {
  const initialInputsState = {}
  const [inputs, setInputs] = useState(initialInputsState)

  const initialFormState = {
    loading: false,
    fieldErrors: {},
    untouched: true,
    error: null,
  }
  const [formState, setFormState] = useState(initialFormState)

  const clearFormState = () => {
    setInputs(initialInputsState)
    setFormState(initialFormState)
  }

  const isValid = () => {
    if (
      formState.untouched ||
      Object.values(formState.fieldErrors).length > 0
    ) {
      return false
    }

    return true
  }

  const getFieldError = (name) => {
    if (formState.fieldErrors[name] && formState.fieldErrors[name].length > 0) {
      return formState.fieldErrors[name][0] || null
    }

    return null
  }

  const getFirstFieldError = (name) => {
    if (
      formState.fieldErrors[name] &&
      formState.fieldErrors[name][0] &&
      formState.fieldErrors[name][0].length > 0
    ) {
      if (formState.fieldErrors[name][0].length > 1) {
        return formState.fieldErrors[name][0].shift() || null
      }
      return formState.fieldErrors[name][0] || null
    }

    return null
  }

  const validateForm = (currentInputs = inputs) => {
    const validator = new Validator(validationRules)
    const fieldErrors = validator.validate(currentInputs) || {}

    setFormState({
      ...formState,
      fieldErrors,
      untouched: false,
    })
  }

  const getValue = (name, defaultValue) => {
    let value = inputs[name]

    if (!value && isNaN(value) && defaultValue) {
      setInputs({
        ...inputs,
        [name]: defaultValue,
      })

      validateForm()

      return defaultValue
    }

    return value
  }

  const handleSubmit = async (callback, event) => {
    if (event) {
      event.preventDefault()
    }

    let error = null

    try {
      if (!isValid()) {
        throw new Error('Form invalid.')
      }

      setFormState({
        ...formState,
        loading: true,
      })
      await callback(inputs)
    } catch (err) {
      if (err.errors) {
        console.error(`GraphQL Error: ${err.errors[0].message}`)
        error = 'Server Error'
      } else {
        error = err.message
      }
    } finally {
      setFormState({
        ...formState,
        error,
        loading: false,
      })
    }
  }

  const handleInputChange = (name) => {
    // return set function
    return (value) => {
      if (value && typeof value === 'object' && value.target) {
        value = value.target.value
      }

      const updatedInputs = {
        ...inputs,
        [name]: value,
      }

      validateForm(updatedInputs)

      setInputs(updatedInputs)
    }
  }

  return {
    clearFormState,
    formState,
    getFieldError,
    getFirstFieldError,
    getValue,
    handleSubmit,
    handleInputChange,
    inputs,
    isValid,
    setFormState,
    setInputs,
    validateForm,
  }
}
