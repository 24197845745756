/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import {
  Button,
  Container,
  Grid2,
  Typography,
  CircularProgress,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import AsseTracAppBar from '../elements/AsseTracAppBar.js'
import ChangePasswordDialog from '../dialogs/ChangePasswordDialog.js'
import { useAuthActions } from '../../store/Auth/AuthActions.js'
import { useUserActions } from '../../store/User/UserActions.js'
import {
  useCancellablePromise,
  canceledPromiseErrorName,
} from '../../promiseUtils.js'
import { formatDate } from '../../util.js'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(6),
  },
  label: {
    color: theme.colors.portlandCement,
  },
  btnWrap: {
    marginTop: theme.spacing(6),
  },
  date: {
    display: 'inline-block',
  },
  progress: {
    marginLeft: theme.spacing(1),
    display: 'inline-block',
  },
  pageWrap: {
    marginTop: theme.spacing(6),

  }
}))

const UsersPage = () => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { userState } = useAuthActions()
  const { getUser } = useUserActions()
  const { cancellablePromise } = useCancellablePromise()
  const [changePasswordDialogOpen, setChangePasswordDialogOpen] =
    useState(false)
  const [user, setUser] = useState()

  const refreshUser = async () => {
    try {
      const tempUser = await cancellablePromise(getUser(userState.username))
      return tempUser
    } catch (err) {
      if (err.name === canceledPromiseErrorName) {
        return
      }
      console.error(err)
    }
  }

  const handleChangePasswordClick = () => {
    setChangePasswordDialogOpen(true)
  }

  useEffect(() => {
    cancellablePromise(refreshUser()).then((res) => {
      setUser(res)
    })
    // refreshUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState])

  return (
    <>
      <Grid2
        container
        direction='column'
        className={classes.pageWrap}
      // width='50%'
      >
        <AsseTracAppBar title='MY ACCOUNT' />
        <Container maxWidth='sm' className={classes.container} >
          {userState && (
            <Grid2 container spacing={1} direction='column'>
              <Grid2 xs={12}>
                <Typography color='secondary' variant='h6'>
                  {`${userState?.signInDetails?.loginId}`}
                </Typography>
              </Grid2>
              <Grid2 xs={12}>
                <Typography
                  className={classes.date}
                  color='secondary'
                  variant='body1'
                >
                  <span className={classes.label}>User since:</span>{' '}
                  {user && formatDate(user.UserCreateDate, 'M/D/Y')}
                </Typography>
                {!user && (
                  <CircularProgress
                    className={classes.progress}
                    size={12}
                    variant='indeterminate'
                  />
                )}
              </Grid2>
              <Grid2 xs={12} className={classes.btnWrap}>
                <Button
                  color='secondary'
                  variant='outlined'
                  onClick={handleChangePasswordClick}
                  sx={{
                    borderRadius: 4
                  }}
                >
                  Change Password
                </Button>
              </Grid2>
            </Grid2>
          )}
        </Container>
      </Grid2>
      <ChangePasswordDialog
        closeForm={() => setChangePasswordDialogOpen(false)}
        open={changePasswordDialogOpen}
        onSave={() =>
          enqueueSnackbar('Successfully changed password.', {
            variant: 'success',
          })
        }
      />
    </>
  )
}

export default UsersPage
