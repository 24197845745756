import React from 'react'
import { useForm } from '../../hooks/Forms.js'
import { useAuthActions } from '../../store/Auth/AuthActions.js'
import { Grid2, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import SpinnerButton from '../elements/SpinnerButton.js'

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    marginBottom: 8,
    marginTop: 8,
    maxWidth: theme.spacing(32),
  },
  verticalGutters: {
    marginBottom: 8,
    marginTop: 8,
  },
}))

export default () => {
  const classes = useStyles()

  const { formState, getFieldError, handleSubmit, handleInputChange, isValid } =
    useForm({
      password: 'required',
      confirmPassword: 'matches:password',
    })

  const { resetPassword } = useAuthActions()

  return (
    <>
      <form onSubmit={(e) => handleSubmit(resetPassword, e)}>
        {formState.error && (
          <Grid2  className={classes.errorMessage}>
            <Typography variant='body1' color='error'>
              {formState.error}
            </Typography>
          </Grid2>
        )}
        <Grid2  className={classes.verticalGutters}>
          <TextField
            autoFocus
            fullWidth
            margin='normal'
            label='NEW PASSWORD'
            error={!!getFieldError('password')}
            name='password'
            variant='outlined'
            onChange={handleInputChange('password')}
            type='password'
          />
        </Grid2>
        <Grid2  className={classes.verticalGutters}>
          <TextField
            fullWidth
            margin='normal'
            label='CONFIRM PASSWORD'
            error={!!getFieldError('confirmPassword')}
            name='confirmPassword'
            variant='outlined'
            onChange={handleInputChange('confirmPassword')}
            type='password'
          />
        </Grid2>
        <Grid2  align='center' className={classes.verticalGutters}>
          <SpinnerButton
            type='submit'
            valid={isValid()}
            loading={formState.loading}
          >
            Save
          </SpinnerButton>
        </Grid2>
      </form>
    </>
  )
}
