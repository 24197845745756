/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Checkbox,
  Stack
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { TreeItem, SimpleTreeView } from '@mui/x-tree-view'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import cloneDeep from 'lodash/cloneDeep.js'

const useStyles = makeStyles((theme) => ({

  child: {
    '&.last .MuiTreeItem-iconContainer': {
      display: 'none',
    },
    '& .MuiIconButton-label': {
      pointerEvents: 'none',
    },
    '&.last .MuiButtonBase-root': {
      marginLeft: theme.spacing(4),
    },
  },
  actionContainer: {
    marginBottom: theme.spacing(1),
    // backgroundColor: 'blue',
    alignItems: 'center',
    justifyContent: 'center'
  },
  divider: {
    backgroundColor: theme.colors.lightSteel
  },
  treeItem: {
    color: 'white',
  },
}))

/**
 * Tests tree item for selected children
 * @param {object} node
 * @return boolean Returns true if has selected child, or false
 */
const hasSelectedChild = (node) => {
  if (typeof node === 'object' && node.children instanceof Array) {
    for (let child of node.children) {
      if (child.selected || hasSelectedChild(child)) {
        return true
      }
    }
  }

  return false
}

const CheckboxTree = ({
  onChange = () => { },
  onSelect = () => { },
  structure = [],
  setStructure = () => { },
  showActions,
  // defaultChecked,
  selected = [],
  setSelected = () => { },
}) => {
  const classes = useStyles()
  const [selectedItems, setSelectedItems] = useState([]);
  const [scopedStructure] = useState(() => {
    let tempStructure = structure.clone
      ? structure.clone()
      : cloneDeep(structure)
    if (Array.isArray(tempStructure)) {
      tempStructure = tempStructure.map((item) => {
        if (selected.includes(item.id)) {
          item.selected = true
        }

        return item
      })
    }

    return tempStructure
  })

  const handleSelectedItemsChange = (event, ids) => {
    if (ids.includes('all') && !selectedItems.includes('all')) {
      setSelectedItems([...structure.map(item => item.id), 'all'])
    } else if (selectedItems.includes('all')) {
      setSelectedItems([])
    }
    else {
      setSelectedItems(ids)
    }

  }
  const handleSelectAllClick = () => {
    setSelectedItems((oldSelected) => oldSelected.length === 0 ?
      structure.map(item => item.id)
      : [])
  }

  const isChildSelected = (layer) => {
    if (layer.children) {
      for (let childLayer of layer.children) {
        if (childLayer.children && isChildSelected(childLayer)) {
          return true
        } else {
          return childLayer.selected
        }
      }
    }
    return layer.selected
  }



  const onLayerToggle = async (layers, isChecked,) => {
    layers = layers instanceof Array ? layers : [layers]

    let tempSelected = []

    if (selected) {
      tempSelected = [...selected]
    }

    for (let layer of layers) {
      if (!layer.geoJson && !layer.selected) {
        // this is a parent, are any children selected
        if (isChildSelected(layer)) {
          isChecked = false
        }
      }

      layer.selected = isChecked

      if (selected) {
        if (isChecked) {
          tempSelected = [...tempSelected, layer.id]
        } else {
          tempSelected = tempSelected.filter((item) => item !== layer.id)
        }
      }

      if (layer.children) {
        for (let child of layer.children) {
          onLayerToggle(child, isChecked, false)
        }
      }
    }

    if (selected) {
      setSelected(tempSelected)
    }

    if (layers[0].geoJson) {
      onSelect(layers[0].uuid, layers[0].geoJson, isChecked)
    }
  }

  const selectAll = async () => {
    onLayerToggle(scopedStructure, true)
  }

  const selectNone = async () => {
    onLayerToggle(scopedStructure, false)
  }

  useEffect(() => {
    onChange(scopedStructure)
    return () => {
      setStructure(scopedStructure)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scopedStructure])

  // useEffect(() => {
  //   if (defaultChecked) {
  //     selectAll()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  /**
   * Recursively generates TreeItems
   * @param { [{ name, [children] }] } children
   * @return JSX
   */
  let itemCount = 0

  const renderItems = (layer) => {
    if (!layer || layer.length === 0) {
      return null
    }

    const children = layer instanceof Array ? layer : [layer]

    const items = children.map((child) => {

      const treeId = `treeitem-${itemCount++}`

      return (
        <TreeItem
          itemId={child.uuid}
          key={child.uuid}
          className={classes.treeItem}
          data-test='treeitem'
          label={
            <div>
              <Checkbox
                checked={child.selected}
                indeterminate={!child.selected && hasSelectedChild(child)}
                onChange={async (e) => onLayerToggle(child, e.target.checked)}

              />
              {child.name}
            </div>
          }
        >
          {renderItems(child.children)}
        </TreeItem>
      )
    })

    return items
  }

  return (
    <Stack spacing={1} sx={{ paddingBottom: 1 }}>


      <SimpleTreeView
        className={classes.root}
        slots={{ collapseIcon: ExpandMoreIcon, expandIcon: ChevronRightIcon }}
      >
        {renderItems(scopedStructure)}
      </SimpleTreeView>
    </Stack>
  )
}

CheckboxTree.propTypes = {
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  structure: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
}

export default CheckboxTree
