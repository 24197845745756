import React from 'react'
import { SvgIcon } from '@mui/material'
import Icons from '../../assets/icons/symbol-defs.svg'

const SystemIcon = ({ className, color, name }) => {
  return (
    <SvgIcon className={className} color={color} sx={{ fill: color }}>
      <svg>
        <use xlinkHref={`${Icons}#${name}`} />
      </svg>
    </SvgIcon>
  )
}

export default SystemIcon
