import React from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs/index.js'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { Grid2, TextField } from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import dayjs from 'dayjs'

import ClientPicker from '../../elements/ClientPicker.js'

const useStyles = makeStyles((theme) => ({
  datepicker: {
    width: '100%',
    '& .MuiInputBase-root': {
      color: 'white',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.lightSteel,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.lightSteel,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.lightSteel,
    },
    '& .MuiInputBase-input::placeholder': {
      color: 'white',
      opacity: 1,
    },
    '& .MuiSvgIcon-root': {
      color: theme.colors.lightSteel,
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
  },
}))
export default ({
  getValue,
  handleInputChange,
  getFieldError,
  getFirstFieldError,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <>
      <Grid2
        container
        spacing={1}
        // alignItems='center'
        direction='column'
      >
        <Grid2 xs={12}>
          <TextField
            autoComplete='off'
            autoFocus
            error={!!getFieldError('name')}
            helperText={getFirstFieldError('name')}
            id='project-name'
            label='Project Name'
            fullWidth
            margin='normal'
            onChange={handleInputChange('name')}
            value={getValue('name')}
            variant='outlined'
            data-test='createProjectDialogName'
            sx={theme.textfield}
          />
        </Grid2>
        <Grid2 >
          <ClientPicker
            error={!!getFieldError('client')}
            helperText={getFieldError('client')}
            value={getValue('client')}
            onChange={handleInputChange('client')}
          />
        </Grid2>
        <Grid2 xs={12}>
          <TextField
            data-test='createProjectDialogDesc'
            autoComplete='off'
            error={!!getFieldError('description')}
            fullWidth
            helperText={getFieldError('description')}
            id='project-description'
            label='Project Description'
            margin='normal'
            multiline
            onChange={handleInputChange('description')}
            maxRows='4'
            name='description'
            value={getValue('description')}
            variant='outlined'
            sx={theme.textfield}
          />
        </Grid2>
        <Grid2 container xs={12} spacing={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid2 xs={12} md={6}>
              <DatePicker
                // autoOk
                className={classes.datepicker}
                // disableToolbar
                // format='MM/dd/yyyy'
                id='start-date'
                KeyboardButtonProps={{
                  'aria-label': 'start date',
                }}
                label='Start Date'
                margin='normal'
                name='startDate'
                onChange={handleInputChange('startDate')}
                value={getValue('startDate', dayjs(new Date()))}
                // variant='inline'
                data-test='createProjectDialogStartDate'
              />
            </Grid2>
            <Grid2 xs={12} md={6}>
              <DatePicker
                // autoOk
                name='endDate'
                className={classes.datepicker}
                // disableToolbar
                // format='MM/dd/yyyy'
                id='end-date'
                KeyboardButtonProps={{
                  'aria-label': 'end date',
                }}
                label='End Date'
                margin='normal'
                onChange={handleInputChange('endDate')}
                value={getValue('endDate', dayjs(new Date()))}
                // variant='inline'
                data-test='createProjectDialogEndDate'
              />
            </Grid2>
          </LocalizationProvider>
        </Grid2>
      </Grid2>
    </>
  )
}
