/* eslint-disable no-unused-vars */

import React, { useEffect, useState, useRef } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs/index.js'
import {
  CircularProgress,
  Container,
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
  useMediaQuery,
  TableContainer,
  Paper
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import moment from 'moment'

import { useReportsActions } from '../../store/Reports/ReportsActions.js'
import { useStateContext } from '../../store/stateContext.js'
import {
  canceledPromiseErrorName,
  useCancellablePromise,
} from '../../promiseUtils.js'
import { safeGet } from '../../util.js'

const useStyles = makeStyles((theme) => ({
  pageWrap: {
    // marginTop: theme.spacing(2),
    backgroundColor: 'red',
    height: '100%'
  },
  controls: {
    backgroundColor: theme.colors.backgroundColor,
    // '& >div': {
    //   zIndex: 1,
    // },
  },
  tweenLabel: {
    textAlign: 'center',
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  formControl: {
    width: '100%',
  },
  datepicker: {
    width: '100%',
  },
  buttonContainer: {
    paddingBottom: theme.spacing(2),
  },
  tableWrap: {
    // position: 'relative',
    // width: '100%',
    // marginTop: 0,
    // [theme.breakpoints.up('lg')]: {
    //   marginTop: -68,
    // },
    // zIndex: 0,
    // '& .MuiPaper-elevation2': {
    //   boxShadow: 'none',
    // },
  },
  tableWrapInner: {
    // position: 'absolute',
    // maxWidth: '100%',
  },
}))
// const tableIcons = {
//   Export: forwardRef((props, ref) => (
//     <SaveAlt color="primary" {...props} ref={ref} />
//   )),
//   FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
//   LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
//   NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
//   PreviousPage: forwardRef((props, ref) => (
//     <ChevronLeft {...props} ref={ref} />
//   )),
//   SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
// }

const DEFAULT_FILTER_START_DATE = moment().subtract(3, 'months')
const DEFAULT_FILTER_END_DATE = moment()
const DEFAULT_FILTER_TYPE = 'ALL'
const DEFAULT_FILTER_SUBTYPE = 'ALL'
const DEFAULT_ASSET_TYPE = { name: 'ALL', id: 'ALL', subType: { items: [] } }
const DEFAULT_ASSET_SUB_TYPE = { name: 'ALL', id: 'ALL' }

const movesByTypesColumns = [
  { header: '16 Foot/CLT', accessorKey: '16Foot/CLT', maxSize: 110 },
  { header: 'Orange Laminate', accessorKey: 'OrangeLaminate', maxSize: 110 },
  { header: 'Composite', accessorKey: 'Composite', maxSize: 110 },
  { header: '24 Foot', accessorKey: '24Foot', maxSize: 110 },
  { header: '30 Foot', accessorKey: '30Foot', maxSize: 110 },
  { header: '40 Foot', accessorKey: '40Foot', maxSize: 110 },
  { header: 'Truck Mats', accessorKey: 'TruckMats', maxSize: 110 },
  { header: 'Move Totals', accessorKey: 'MoveTotals', maxSize: 110, },
]

const individualMovesColumns = [
  { header: 'Date', accessorKey: '16Foot/CLT', maxSize: 110 },
  { header: 'Asset Type', accessorKey: 'OrangeLaminate', maxSize: 110 },
  { header: 'ID', accessorKey: 'Composite', maxSize: 110 },
  { header: 'From', accessorKey: '24Foot', maxSize: 110 },
  { header: 'To', accessorKey: '30Foot', maxSize: 110 },
  { header: 'Move Type', accessorKey: '40Foot', maxSize: 110 },
  { header: 'Distance', accessorKey: 'TruckMats', maxSize: 110 },
]

export default ({ customerId, projectId }) => {
  const inputLabelType = useRef()
  const inputLabelSubtype = useRef()
  const classes = useStyles()
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const { cancellablePromise } = useCancellablePromise()

  const [tableData, setTableData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [assetTypes, setAssetTypes] = useState([DEFAULT_ASSET_TYPE])
  const [assetSubtypes, setAssetSubtypes] = useState([DEFAULT_ASSET_SUB_TYPE])
  const [filterStartDate, setFilterStartDate] = useState(
    DEFAULT_FILTER_START_DATE,
  )
  const [filterEndDate, setFilterEndDate] = useState(DEFAULT_FILTER_END_DATE)
  const [filterType, setFilterType] = useState(DEFAULT_FILTER_TYPE)
  const [filterSubtype, setFilterSubtype] = useState(DEFAULT_FILTER_SUBTYPE)

  const { listAssetMovementReports } = useReportsActions()
  const { state } = useStateContext()


  const movesByTypeTable = useMaterialReactTable({
    columns: movesByTypesColumns,
    data: [],
    enableTopToolbar: false,
    enablePagination: false,
    enableSorting: false,
    enableColumnActions: false,
    enableBottomToolbar: false,
    mrtTheme: {
      baseBackgroundColor: theme.colors.backgroundColor,

    },
    muiTablePaperProps: {
      elevation: 0
    },
    muiTableProps: {
      color: 'white',
      sx: {
        color: 'white',
        '& .MuiTableCell-root': {
          color: 'white',
        },
        '& .MuiTableHead-cell': {
          color: 'white',
        },
        '& .MuiTableBody-root': {
          color: 'white',
        },
      }
    },

  });

  const individualTable = useMaterialReactTable({
    columns: individualMovesColumns,
    data: tableData ?? [],
    enableTopToolbar: false,
    enablePagination: false,
    enableSorting: false,
    enableColumnActions: false,
    enableBottomToolbar: false,
    mrtTheme: {
      baseBackgroundColor: theme.colors.backgroundColor,

    },
    muiTablePaperProps: {
      elevation: 0
    },
    muiTableProps: {
      color: 'white',
      sx: {
        color: 'white',
        '& .MuiTableCell-root': {
          color: theme.colors.lightSteel,
        },
        '& .MuiTableHead-cell': {
          color: theme.colors.lightSteel,
        },
        '& .MuiTableBody-root': {
          color: theme.colors.lightSteel,
        },
      }
    },
  });



  const massageData = (data) => {
    const formattedData = data.reduce((acc, current) => {
      let distance = safeGet('totalDistanceTraveled', current, '')
      if (typeof distance === 'number') {
        distance = distance.toFixed(2) + ' mi'
      }

      let OnRoadDistance = safeGet('onRoadDistance', current, '')
      if (typeof OnRoadDistance === 'number') {
        OnRoadDistance = OnRoadDistance.toFixed(2) + ' mi'
      }
      acc.push({
        startDate: moment
          .unix(safeGet('startTs', current, ''))
          .format('M/D/YY'),
        startTime: moment.unix(safeGet('startTs', current, '')).format('h:mma'),
        endDate: moment.unix(safeGet('stopTs', current, '')).format('M/D/YY'),
        endTime: moment.unix(safeGet('stopTs', current, '')).format('h:mm a'),
        name: safeGet('id', current, ''),
        type: safeGet('assetType.name', current, ''),
        subType: safeGet('assetSubType.name', current, ''),
        id: safeGet('id', current, ''),
        uuid: safeGet('uuid', current, ''),
        fromLat: safeGet('movedFromCoordinate.latitude', current, ''),
        fromLong: safeGet('movedFromCoordinate.longitude', current, ''),
        toLat: safeGet('movedToCoordinate.latitude', current, ''),
        toLong: safeGet('movedToCoordinate.longitude', current, ''),
        distance: distance,
        onRoadDistance: OnRoadDistance,
      })

      return acc
    }, [])
    return formattedData
  }

  const getMovesData = () => {
    setLoading(true)

    let params = {
      customerId: customerId,
      destProjectIdStopTs: {
        between: [
          { destProjectId: projectId, stopTs: moment(filterStartDate).unix() },
          { destProjectId: projectId, stopTs: moment(filterEndDate).unix() },
        ],
      },
    }
    let filter = {}
    if (filterType && filterType !== 'ALL') {
      filter = {
        ...filter,
        assetTypeId: {
          eq: filterType,
        },
      }
    }
    if (filterSubtype && filterSubtype !== 'ALL') {
      filter = {
        ...filter,
        assetSubTypeId: {
          eq: filterSubtype,
        },
      }
    }
    if (Object.keys(filter).length > 0) {
      params = {
        ...params,
        filter,
      }
    }

    cancellablePromise(listAssetMovementReports(params))
      .then((results) => {
        setTableData(massageData(results))
        setLoading(false)
      })
      .catch((error) => {
        if (error.name === canceledPromiseErrorName) {
          return
        }
        console.error(error)
      })
    setLoading(false)
  }

  const handleTypeFilterChange = (type) => {
    setFilterType(type.id)
    if (
      type.id !== 'ALL' &&
      !type.subType.items.find((subtype) => subtype.id === 'ALL')
    ) {
      type.subType.items.unshift({ name: 'ALL', id: 'ALL' })
    }
    setAssetSubtypes(type.subType.items || [])
    setFilterSubtype(type.id === 'ALL' ? '' : DEFAULT_FILTER_SUBTYPE)
  }
  const getTypeFromId = (id, subtype) => {
    if (subtype) {
      return getTypeFromId(filterType).subType.items.find(
        (asset) => asset.id === id,
      )
    }
    return assetTypes.find((asset) => asset.id === id)
  }

  useEffect(() => {
    if (customerId) {
      getMovesData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterEndDate, filterStartDate, filterType, filterSubtype, customerId])

  useEffect(() => {
    const tempAssetTypes = state.constants.assetTypes
    const allItem = tempAssetTypes.find((x) => x.id === 'ALL')
    if (!allItem) {
      tempAssetTypes.unshift({ name: 'ALL', id: 'ALL', subType: { items: [] } })
    }
    setAssetTypes(tempAssetTypes)
  }, [state.constants.assetTypes])

  return (
    <Container sx={{ height: '100%', paddingTop: 5 }}>
      {loading && (
        <Grid2 container justify='center' className={classes.pageWrap}>
          <CircularProgress color='primary' variant='indeterminate' />
        </Grid2>
      )}
      {
        !loading && (
          <Grid2 container spacing={4} sx={{ marginTop: 2 }}>
            <Grid2>
              <Typography sx={{ color: theme.colors.lightSteel }}>MOVES BY TYPE</Typography>
              <Grid2>
                <MaterialReactTable table={movesByTypeTable} />
              </Grid2>
            </Grid2>
            <Grid2>
              <Typography sx={{ color: theme.colors.lightSteel }}>INDIVIDUAL MOVES</Typography>
              <MaterialReactTable table={individualTable} />
            </Grid2>
          </Grid2>
        )
      }
      {/* {!loading && (
        <Grid2
          container
          direction='column'
          spacing={2}
          className={classes.pageWrap}
        >
          {
            // Controls container
          }
          <Grid2
            container

            className={classes.controls}
            justifyContent='flex-start'
            spacing={2}
            xs={12}
          >
            {
              // Dates container
            }
            <Grid2 container xs={12} lg={4} sx={{ backgroundColor: 'yellow' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid2 container xs={12}>
                  <Typography className={classes.longLabel} color='secondary'>
                    Moves completed on dates between
                  </Typography>
                </Grid2>
                <Grid2
                  container

                  spacing={1}
                  alignItems='center'
                  className={classes.datesRow}
                >
                  <Grid2 xs={12} md={5}>
                    <DatePicker
                      className={classes.datepicker}
                      // disableToolbar
                      format='MM/dd/yyyy'
                      id='start-date'
                      KeyboardButtonProps={{
                        'aria-label': 'start date',
                      }}
                      margin='normal'
                      name='startDate'
                      // variant='inline'
                      value={filterStartDate}
                      onChange={(e) => setFilterStartDate(e)}
                    />
                  </Grid2>
                  <Grid2 hidden={smDown} md={2}>
                    <Typography
                      className={classes.tweenLabel}
                      color='secondary'
                    >
                      {' '}
                      and{' '}
                    </Typography>
                  </Grid2>
                  <Grid2 xs={12} md={5}>
                    <DatePicker
                      className={classes.datepicker}
                      // disableToolbar
                      format='MM/dd/yyyy'
                      id='end-date'
                      KeyboardButtonProps={{
                        'aria-label': 'end date',
                      }}
                      margin='normal'
                      name='endDate'
                      // variant='inline'
                      value={filterEndDate}
                      onChange={(e) => setFilterEndDate(e)}
                    />
                  </Grid2>
                </Grid2>
              </LocalizationProvider>
            </Grid2>
            {
              // Types container
            }
            <Grid2
              container

              spacing={2}
              alignItems='flex-end'
              xs={12}
              lg={4}
            >
              <Grid2 xs={12} sm={6}>
                <FormControl
                  variant='outlined'
                  className={classes.formControl}
                >
                  <InputLabel ref={inputLabelType}>Type</InputLabel>
                  <Select
                    value={filterType}
                    // labelWidth={
                    //   inputLabelType.current
                    //     ? inputLabelType.current.offsetWidth
                    //     : 0
                    // }
                    onChange={(e) => {
                      handleTypeFilterChange(getTypeFromId(e.target.value))
                    }}
                  >
                    {assetTypes &&
                      assetTypes.map((type, index) => (
                        <MenuItem
                          key={'type-' + index}
                          value={type.id}
                          name={type.name}
                        >{`${type.name || getTypeFromId(type.id)}`}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid2>
              <Grid2 xs={12} sm={6}>
                <FormControl
                  variant='outlined'
                  className={classes.formControl}
                  disabled={filterType === 'ALL'}
                >
                  <InputLabel ref={inputLabelSubtype}>Subtype</InputLabel>
                  <Select
                    value={filterSubtype}
                    // labelWidth={
                    //   inputLabelSubtype.current
                    //     ? inputLabelSubtype.current.offsetWidth
                    //     : 0
                    // }
                    onChange={(e) => {
                      setFilterSubtype(getTypeFromId(e.target.value, true).id)
                    }}
                  >
                    {assetSubtypes &&
                      assetSubtypes.map((subtype, index) => (
                        <MenuItem
                          key={'subtype-' + index}
                          value={subtype.id}
                        >{`${subtype.name || getTypeFromId(subtype.id, true)
                          }`}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid2>
            </Grid2>
          </Grid2>
          {
            // Table container
          }
          <Grid2 xs={12} className={classes.tableWrap}>
            {tableData && (
              <div className={classes.tableWrapInner}>
                <MaterialReactTable
                  // elevation={0}
                  // icons={tableIcons}
                  // options={{
                  //   search: false,
                  //   toolbarButtonAlignment: 'right',
                  //   showTitle: false,
                  //   exportButton: true,
                  //   exportAllData: true,
                  //   headerStyle: {
                  //     minHeight: '48px',
                  //   },
                  // }}
                  columns={[
                    { header: 'Start Date', accessorKey: 'startDate' },
                    { header: 'Start Time', accessorKey: 'startTime' },
                    { header: 'End Date', accessorKey: 'endDate' },
                    { header: 'End Time', accessorKey: 'endTime' },
                    { header: 'Type', accessorKey: 'type' },
                    { header: 'Subtype', accessorKey: 'subType' },
                    { header: 'Distance', accessorKey: 'distance' },
                    { header: 'Distance On Roads', accessorKey: 'onRoadDistance' },
                    {
                      header: 'UUID',
                      accessorKey: 'uuid',
                      cellStyle: { minWidth: '136px' },
                    },
                    { header: 'From Lat', accessorKey: 'fromLat' },
                    { header: 'From Long', accessorKey: 'fromLong' },
                    { header: 'To Lat', accessorKey: 'toLat' },
                    { header: 'To Long', accessorKey: 'toLong' },
                    { header: 'Dimensions', accessorKey: 'dimensions' },
                    { header: 'Supplier', accessorKey: 'supplier' },
                    { header: 'Asset Id', accessorKey: 'assetId' },
                    { header: 'Id', accessorKey: 'id' },
                  ]}
                  data={tableData}
                />
              </div>
            )}
          </Grid2>
        </Grid2>
      )} */}
    </Container>

  )
}
