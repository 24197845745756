import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuthActions } from '../../store/Auth/AuthActions.js'

const ProtectedRoute = ({
  children,
  groups = [],
  redirect = '/',
}) => {
  const { memberOf } = useAuthActions()

  groups = Array.isArray(groups) ? groups : [groups];

  if (groups.some((group) => !memberOf(group))) {
    // redirect to home page
    return <Navigate to={redirect} replace />
  }

  return children

}

export default ProtectedRoute
