import { generateClient } from 'aws-amplify/api'

import { useStateContext } from '../stateContext.js'
import { SET_CONSTANTS } from './ConstantsReducer.js'

import * as queries from '../../graphql/queries.js'
import * as mutations from '../../graphql/mutations.js'
import * as customQueries from '../../graphql/customQueries.js'

export const useConstantsActions = () => {
  const { dispatch, state } = useStateContext()

  const client = generateClient();


  const getAssetTypesGraphQL = async () => {
    try {
      const { data } = await client.graphql(
        {
          query: customQueries.listAssetTypesAndSubTypes,
          variables: { limit: 1000 }
        }
      )
      return data.listAssetTypes.items
    } catch (error) {
      console.error('Error occurred: ', error)
    }
  }
  const getDimensionsGraphQL = async () => {
    try {
      const { data } = await client.graphql(
        {
          query: queries.listDimensionss,
          variables: { limit: 1000 }
        }
      )
      return data.listDimensionss.items
    } catch (error) {
      console.error('Error occurred: ', error)
    }
  }
  const getSuppliersGraphQL = async () => {
    try {
      const { data } = await client.graphql(
        {
          query: queries.listSuppliers,
          variables: { limit: 1000 }
        }
      )
      return data.listSuppliers.items
    } catch (error) {
      console.error('Error occurred: ', error)
    }
  }

  const getConstants = async (reFetch) => {
    if (reFetch || state.constants.assetTypes.length === 0) {
      const assetTypes = await getAssetTypesGraphQL()
      const dimensions = await getDimensionsGraphQL()
      const suppliers = await getSuppliersGraphQL()
      const constants = {
        assetTypes: assetTypes,
        dimensions: dimensions,
        suppliers: suppliers,
      }
      dispatch({
        type: SET_CONSTANTS,
        payload: constants,
      })
      return constants
    }

    return state.constants
  }

  const getAssetTypes = async () => {
    const constants = await getConstants(true)
    return constants.assetTypes || []
  }

  const createAssetType = async ({
    name,
    iconName,
    idleThreshold,
    offlineThreshold,
  }) => {
    const { data, errors } = await client.graphql(
      {
        query: mutations.createAssetType,
        variables: {
          input: {
            name,
            iconName,
            idleThreshold,
            offlineThreshold,
          },
        }
      }
    )

    if (errors) {
      throw errors
    }

    return data.createAssetType
  }

  const updateAssetType = async ({
    assetType,
    name,
    iconName,
    idleThreshold,
    offlineThreshold,
  }) => {
    const { data, errors } = await client.graphql(
      {
        query: mutations.updateAssetType,
        variables: {
          input: {
            id: assetType.id,
            name,
            iconName,
            idleThreshold,
            offlineThreshold,
          },
        }
      }
    )

    if (errors) {
      throw errors
    }

    return data.updateAssetType
  }

  const createSubType = async ({
    assetType,
    name,
    iconName,
    idleThreshold,
  }) => {
    if (typeof assetType !== 'object' || !assetType.id) {
      throw new Error('Must provide assetType object')
    }

    const { data, errors } = await client.graphql(
      {
        query: mutations.createAssetSubType,
        variables: {
          input: {
            name,
            iconName,
            idleThreshold,
            typeId: assetType.id,
          },
        }
      }
    )

    if (errors) {
      throw errors
    }

    return data.createAssetType
  }

  const updateAssetSubType = async ({
    assetSubType,
    name,
    iconName,
    idleThreshold,
  }) => {
    const { data, errors } = await client.graphql(
      {
        query: mutations.updateAssetSubType,
        variables: {
          input: {
            id: assetSubType.id,
            name,
            iconName,
            idleThreshold,
          },
        }
      }
    )

    if (errors) {
      throw errors
    }

    return data.updateAssetType
  }

  return {
    createAssetType,
    createSubType,
    getConstants,
    getAssetTypes,
    updateAssetType,
    updateAssetSubType,
  }
}
