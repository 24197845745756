export const INIT_CONSTANTS = 'INIT_CONSTANTS'
export const SET_CONSTANTS = 'SET_CONSTANTS'
export const ADD_ASSET_TYPE = 'ADD_ASSET_TYPE'
export const UPDATE_ASSET_TYPE = 'UPDATE_ASSET_TYPE'

export const initialState = {
  dimensions: [],
  suppliers: [],
  assetTypes: [],
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT_CONSTANTS:
      return {
        ...initialState,
      }
    case SET_CONSTANTS:
      return {
        ...state,
        dimensions: [...payload.dimensions],
        suppliers: [...payload.suppliers],
        assetTypes: [...payload.assetTypes],
      }
    case ADD_ASSET_TYPE:
      return {
        ...state,
        assetTypes: [...state.assetTypes, payload.addAssetType],
      }
    case UPDATE_ASSET_TYPE:
      return {
        ...state,
        assetTypes: state.assetTypes.map((at) => {
          if (at.id === payload.updateAssetType.id) {
            return {
              ...payload.updateAssetType,
            }
          }
          return at
        }),
      }

    default:
      return state
  }
}
