import React, { useState, useEffect } from 'react'
import {
  Button,
  Container,
  FormControl,
  Grid2,
  MenuItem,
  Select,
  Typography,
  CircularProgress,
} from '@mui/material'
import { makeStyles } from '@mui/styles'

import AsseTracAppBar from '../elements/AsseTracAppBar.js'
import SystemIcon from '../elements/SystemIcon.js'
import InviteUsersDialog from '../dialogs/InviteUsersDialog.js'
import { useCustomerActions } from '../../store/Customer/CustomerActions.js'
import { useStateContext } from '../../store/stateContext.js'
import { useUserActions } from '../../store/User/UserActions.js'
import { SET_USER_LIST } from '../../store/User/UserReducer.js'

import { formatDate, safeGet } from '../../util.js'
import {
  useCancellablePromise,
  canceledPromiseErrorName,
} from '../../promiseUtils.js'

const useStyles = makeStyles((theme) => ({
  alignRight: {
    textAlign: 'right',
  },
  container: {
    marginTop: theme.spacing(5),
  },
  cellLabel: {
    color: theme.colors.lightSteel,
  },
  listContainer: {
    marginTop: theme.spacing(2),
  },
  rowWrap: {
    width: '100%',
  },
  selectBox: {
    textAlign: 'left',
  },
  spinnerContainer: {
    minHeight: '200px',
  },
  select: {
    width: 120,
    color: 'white',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.lightSteel,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.lightSteel,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.lightSteel,
    },
    '& .MuiSvgIcon-root': {
      color: theme.colors.lightSteel,
    },
    '& .MuiSelect-select': {
      color: 'white',
    },
  },
}))

const UsersPage = () => {
  const classes = useStyles()
  const {
    getUsersInGroup,
    usersState,
    addUserToGroup,
    removeUserFromGroup,
    enableUser,
    disableUser,
  } = useUserActions()
  const { customerState, getActiveCustomer } = useCustomerActions()
  const { dispatch } = useStateContext()
  const { cancellablePromise } = useCancellablePromise()

  const [inviteUsersOpen, setInviteUsersOpen] = useState(false)
  const [loading, setLoading] = useState(true)

  const refreshUserList = async () => {
    try {
      setLoading(true)

      const activeCustomer =
        (await cancellablePromise(getActiveCustomer())) || {}
      const customerGroup = activeCustomer.userGroups
        .filter((group) => group.indexOf('customer-') === 0)
        .pop()
      const customerAdminGroup = activeCustomer.adminGroups
        .filter((group) => group.indexOf('customerAdmin-') === 0)
        .pop()
      let adminList = await cancellablePromise(
        getUsersInGroup(customerAdminGroup),
      )
      let userList = await cancellablePromise(getUsersInGroup(customerGroup))
      // Distinguish admins form users
      userList = userList.map((user) => {
        const loc = adminList.findIndex((a) => {
          return a.Username === user.Username
        })
        if (loc !== -1) {
          adminList[loc].Admin = true
          return adminList[loc]
        }
        return user
      })

      userList = userList.reduce((acc, current) => {
        acc.push({
          ...current,
          Email: current.Attributes.find((at) => {
            if (at.Name !== 'email') {
              return false
            }
            return true
          }).Value,
        })
        return acc
      }, [])

      dispatch({ type: SET_USER_LIST, payload: { userList } })
      setLoading(false)
      return userList
    } catch (err) {
      if (err.name === canceledPromiseErrorName) {
        return
      }
      console.error(err)
    }

    setLoading(false)
  }

  const handleAdminChange = async (user, value) => {
    try {
      const activeCustomer =
        (await cancellablePromise(getActiveCustomer())) || {}
      const customerAdminGroup = activeCustomer.adminGroups
        .filter((group) => group.indexOf('customerAdmin-') === 0)
        .pop()
      if (value === 'true') {
        await cancellablePromise(addUserToGroup(user.Email, customerAdminGroup))
      } else {
        await cancellablePromise(
          removeUserFromGroup(user.Email, customerAdminGroup),
        )
      }
      refreshUserList()
    } catch (error) {
      if (error.name === canceledPromiseErrorName) {
        return
      }
      console.error(error)
    }
  }

  const handleStatusChange = async (user, status) => {
    try {
      if (status === 'Active') {
        await cancellablePromise(enableUser(user.Email))
      } else {
        await cancellablePromise(disableUser(user.Email))
      }
      // fetch new list
      refreshUserList()
    } catch (error) {
      if (error.name === canceledPromiseErrorName) {
        return
      }
      console.error(error)
    }
  }

  useEffect(() => {
    refreshUserList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerState.activeCustomer])

  return (
    <>
      <Grid2
        container
        direction='column'
      >
        <AsseTracAppBar title='USERS' />
        <Container maxWidth='lg' className={classes.container}>
          <Grid2 container justifyContent='space-between'>
            <Grid2 xs={6}>
              <Typography color='secondary' variant='subtitle1'>
                {usersState.userList.length}
                {usersState.userList.length === 1 ? (
                  <span> user</span>
                ) : (
                  <span> total users</span>
                )}
              </Typography>
            </Grid2>
            <Grid2 xs={6} className={classes.alignRight}>
              <Button
                color='primary'
                onClick={() => setInviteUsersOpen(true)}
                variant='contained'
                sx={{ borderRadius: 5 }}
              >
                <SystemIcon b name='icn_add' color='white' /> INVITE USERS
              </Button>
            </Grid2>
          </Grid2>

          {loading && (
            <Grid2
              container
              alignItems='center'
              justify='center'
              className={classes.spinnerContainer}
            >
              <Grid2 >
                <CircularProgress variant='indeterminate' />
              </Grid2>
            </Grid2>
          )}
          {!loading && (
            <Grid2 container className={classes.listContainer} spacing={2}>
              {usersState.userList.map((user, index) => (
                <Grid2
                  container
                  className={classes.rowWrap}
                  key={`user-row-${index}`}
                  alignItems='center'
                  spacing={2}
                >
                  <Grid2 size={4}>
                    <Typography color='secondary'>{user.Email}</Typography>
                  </Grid2>

                  <Grid2 size={2}>
                    <Typography color='secondary'>
                      <span className={classes.cellLabel}>Since: </span>
                      {formatDate(user.UserCreateDate, 'M/D/Y')}
                    </Typography>
                  </Grid2>

                  <Grid2 size={3}>
                    <Typography color='secondary'>
                      <span className={classes.cellLabel}>Last Login: </span>
                      {formatDate(user.UserLastModifiedDate, 'M/D/Y')}
                    </Typography>
                  </Grid2>

                  <Grid2 container justifyContent={'center'} spacing={2} size={3}>
                    <FormControl
                      variant='outlined'
                    >
                      <Select
                        onChange={(e) =>
                          handleAdminChange(user, e.target.value)
                        }
                        value={safeGet('Admin', user, false)}
                        className={classes.select}
                      >
                        <MenuItem value='true'>Admin</MenuItem>
                        <MenuItem value='false'>User</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl
                      variant='outlined'
                    >
                      <Select
                        onChange={(e) =>
                          handleStatusChange(user, e.target.value)
                        }
                        value={user.Enabled ? 'Active' : 'Inactive'}
                        className={classes.select}
                      >
                        <MenuItem value='Active'>Active</MenuItem>
                        <MenuItem value='Inactive'>Inactive</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid2>
                </Grid2>
              ))}
            </Grid2>
          )}
        </Container>
      </Grid2>
      <InviteUsersDialog
        closeForm={() => setInviteUsersOpen(false)}
        open={inviteUsersOpen}
        onSave={refreshUserList}
      />
    </>
  )
}

export default UsersPage
