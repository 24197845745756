import React, { useState } from 'react'
import { Slider } from '@mui/material'
import { makeStyles, withStyles } from '@mui/styles'
import moment from 'moment'
import { colorPalette } from '../../../theme.js'
// import TimePopup from './TimePopup.js'

const MIN_VALUE = -1000
const MAX_VALUE = 0
const TIME_RANGE = 3 * 30 * 24 * 60 * 60 // 3 months in seconds
const A_VAR = TIME_RANGE / (MIN_VALUE * MIN_VALUE) // for use in quadratic

const useStyles = makeStyles((theme) => ({
  root: {
    // partially styled in parent component (i.e. size & position)
  },
  innerWrap: {
    backgroundColor: theme.colors.backgroundColor,
    borderTopWidth: 1,
    borderTopColor: theme.colors.lightSteel,
    borderTopStyle: 'solid',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(7),
    paddingLeft: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      borderBottomWidth: 1,
      borderBottomColor: theme.colors.lightSteel,
      borderBottomStyle: 'solid',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
  },
}))

const CustomSlider = withStyles({
  mark: {
    width: 1,
    height: 8,
    marginTop: -3,
    backgroundColor: colorPalette.portlandCement,
  },
  rail: {
    height: 1,
    opacity: 1,
    backgroundColor: colorPalette.portlandCement,
  },
})(Slider)

const marks = [
  { label: '3M', value: MIN_VALUE },
  { label: '1.5M', value: MIN_VALUE - MIN_VALUE / 4 },
  { label: '20D', value: MIN_VALUE / 2 },
  { label: '5D', value: MIN_VALUE / 4 },
  { label: 'NOW', value: 0 },
]

const selectionToDate = (x) => {
  const duration = A_VAR * x * x
  const date = moment().add(-duration, 'seconds')

  return date
}

const formatValue = (x) => {
  const date = selectionToDate(x)
  return date.format('M/D/YY h:mma')
}

const TimelineSlider = ({ className, onChange = () => { } }) => {
  const classes = useStyles()
  const [value, setValue] = useState(0)

  const handleChange = (e, newValue) => {
    setValue(newValue)
    onChange(selectionToDate(newValue))
  }

  return (
    <div className={`${className} ${classes.root}`}>
      <div className={classes.innerWrap}>
        <CustomSlider
          min={MIN_VALUE}
          max={MAX_VALUE}
          marks={marks}
          onChange={handleChange}
          value={value}
          valueLabelDisplay='auto'
          valueLabelFormat={formatValue}
          // valuelabelcomponent={TimePopup}
        />
      </div>
    </div>
  )
}

export default TimelineSlider
