import { useStateContext } from '../stateContext.js'
import { SET_MOVES } from './ReportsReducer.js'

import * as queries from '../../graphql/queries.js'
import { generateClient } from 'aws-amplify/api'

const DEFAULT_RECORD_LIMIT = 100000

export const useReportsActions = () => {
  const { dispatch } = useStateContext();

  const client = generateClient();

  const listAssetMovementReports = async (filters) => {
    const { data } = await client.graphql(
      {
        query: queries.reportsByCustomer,
        variables: {
          limit: DEFAULT_RECORD_LIMIT,
          sortDirection: 'DESC',
          ...filters,
        }
      }
    )
    const movesList = data.reportsByCustomer.items

    dispatch({
      type: SET_MOVES,
      payload: { movesList },
    })

    return movesList
  }

  return {
    listAssetMovementReports,
  }
}
