/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAsset = /* GraphQL */ `
  query GetAsset($id: ID!) {
    getAsset(id: $id) {
      id
      uuid
      createdAt
      updatedAt
      status
      customerId
      deviceId
      name
      userGroups
      adminGroups
      description
      version
      unitNumber
      make
      model
      year
      capacity
      activatedOn
      deactivatedOn
      toolType
      size
      annualInspection
      type {
        id
        name
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      subType {
        id
        name
        typeId
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      dimensions {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      supplier {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      eventTs
      receivedTs
      firstCheckedIn
      coordinate {
        latitude
        longitude
        __typename
      }
      altitude
      battery
      rssi
      accelerometer
      speed
      course
      projectId
      movingStatus
      movingCoordinate {
        latitude
        longitude
        __typename
      }
      movingTs
      movingStartTs
      movedCoordinate {
        latitude
        longitude
        __typename
      }
      movedTs
      movedProjectId
      __typename
    }
  }
`;
export const listAssets = /* GraphQL */ `
  query ListAssets(
    $filter: ModelAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        uuid
        createdAt
        updatedAt
        status
        customerId
        deviceId
        name
        userGroups
        adminGroups
        description
        version
        unitNumber
        make
        model
        year
        capacity
        activatedOn
        deactivatedOn
        toolType
        size
        annualInspection
        eventTs
        receivedTs
        firstCheckedIn
        altitude
        battery
        rssi
        accelerometer
        speed
        course
        projectId
        movingStatus
        movingTs
        movingStartTs
        movedTs
        movedProjectId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAssetHistory = /* GraphQL */ `
  query GetAssetHistory($assetId: ID!, $eventTs: Int!) {
    getAssetHistory(assetId: $assetId, eventTs: $eventTs) {
      assetId
      eventTs
      uuid
      deviceId
      unitNumber
      customerId
      receivedTs
      coordinate {
        latitude
        longitude
        __typename
      }
      altitude
      battery
      rssi
      accelerometer
      speed
      course
      userGroups
      adminGroups
      projectId
      assetType {
        id
        name
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      assetSubType {
        id
        name
        typeId
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAssetHistorys = /* GraphQL */ `
  query ListAssetHistorys(
    $assetId: ID
    $eventTs: ModelIntKeyConditionInput
    $filter: ModelAssetHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAssetHistorys(
      assetId: $assetId
      eventTs: $eventTs
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        assetId
        eventTs
        uuid
        deviceId
        unitNumber
        customerId
        receivedTs
        altitude
        battery
        rssi
        accelerometer
        speed
        course
        userGroups
        adminGroups
        projectId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAssetMovementCoordinate = /* GraphQL */ `
  query GetAssetMovementCoordinate(
    $movementId: ID!
    $source: String!
    $index: Int!
  ) {
    getAssetMovementCoordinate(
      movementId: $movementId
      source: $source
      index: $index
    ) {
      movementId
      source
      index
      eventTs
      latitude
      longitude
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAssetMovementCoordinates = /* GraphQL */ `
  query ListAssetMovementCoordinates(
    $movementId: ID
    $sourceIndex: ModelAssetMovementCoordinatePrimaryCompositeKeyConditionInput
    $filter: ModelAssetMovementCoordinateFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAssetMovementCoordinates(
      movementId: $movementId
      sourceIndex: $sourceIndex
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        movementId
        source
        index
        eventTs
        latitude
        longitude
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAssetMovementReport = /* GraphQL */ `
  query GetAssetMovementReport($id: ID!) {
    getAssetMovementReport(id: $id) {
      id
      uuid
      assetId
      startTs
      stopTs
      state
      onRoadDistance
      offRoadDistance
      totalDistanceTraveled
      movedToCoordinate {
        latitude
        longitude
        __typename
      }
      movedFromCoordinate {
        latitude
        longitude
        __typename
      }
      prevProjectId
      destProjectId
      customerId
      firstMove
      userGroups
      adminGroups
      assetTypeId
      assetType {
        id
        name
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      assetSubTypeId
      assetSubType {
        id
        name
        typeId
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAssetMovementReports = /* GraphQL */ `
  query ListAssetMovementReports(
    $filter: ModelAssetMovementReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssetMovementReports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uuid
        assetId
        startTs
        stopTs
        state
        onRoadDistance
        offRoadDistance
        totalDistanceTraveled
        prevProjectId
        destProjectId
        customerId
        firstMove
        userGroups
        adminGroups
        assetTypeId
        assetSubTypeId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAssetType = /* GraphQL */ `
  query GetAssetType($id: ID!) {
    getAssetType(id: $id) {
      id
      name
      subType {
        nextToken
        __typename
      }
      iconName
      idleThreshold
      offlineThreshold
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAssetTypes = /* GraphQL */ `
  query ListAssetTypes(
    $filter: ModelAssetTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssetTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAssetSubType = /* GraphQL */ `
  query GetAssetSubType($id: ID!) {
    getAssetSubType(id: $id) {
      id
      name
      typeId
      iconName
      idleThreshold
      offlineThreshold
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAssetSubTypes = /* GraphQL */ `
  query ListAssetSubTypes(
    $filter: ModelAssetSubTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssetSubTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        typeId
        iconName
        idleThreshold
        offlineThreshold
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDimensions = /* GraphQL */ `
  query GetDimensions($id: ID!) {
    getDimensions(id: $id) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDimensionss = /* GraphQL */ `
  query ListDimensionss(
    $filter: ModelDimensionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDimensionss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSupplier = /* GraphQL */ `
  query GetSupplier($id: ID!) {
    getSupplier(id: $id) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSuppliers = /* GraphQL */ `
  query ListSuppliers(
    $filter: ModelSupplierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSuppliers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      name
      email
      description
      status
      assetsVersion
      projects {
        nextToken
        __typename
      }
      assets {
        nextToken
        __typename
      }
      userGroups
      adminGroups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        description
        status
        assetsVersion
        userGroups
        adminGroups
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      name
      customerId
      client
      description
      boundaries {
        latitude
        longitude
        __typename
      }
      overlays {
        name
        __typename
      }
      startDate
      endDate
      status
      userGroups
      adminGroups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        customerId
        client
        description
        startDate
        endDate
        status
        userGroups
        adminGroups
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const assetByUuid = /* GraphQL */ `
  query AssetByUuid(
    $uuid: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    assetByUuid(
      uuid: $uuid
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uuid
        createdAt
        updatedAt
        status
        customerId
        deviceId
        name
        userGroups
        adminGroups
        description
        version
        unitNumber
        make
        model
        year
        capacity
        activatedOn
        deactivatedOn
        toolType
        size
        annualInspection
        eventTs
        receivedTs
        firstCheckedIn
        altitude
        battery
        rssi
        accelerometer
        speed
        course
        projectId
        movingStatus
        movingTs
        movingStartTs
        movedTs
        movedProjectId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const assetsByCustomer = /* GraphQL */ `
  query AssetsByCustomer(
    $customerId: ID
    $projectId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    assetsByCustomer(
      customerId: $customerId
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uuid
        createdAt
        updatedAt
        status
        customerId
        coordinate {
          latitude
          longitude
        }
        deviceId
        name
        userGroups
        adminGroups
        description
        version
        unitNumber
        make
        model
        year
        capacity
        activatedOn
        deactivatedOn
        toolType
        size
        annualInspection
        eventTs
        receivedTs
        firstCheckedIn
        altitude
        battery
        rssi
        accelerometer
        speed
        type {
          id
          name
          iconName
          idleThreshold
          offlineThreshold
          createdAt
          updatedAt
        }
        subType {
          id
          name
          typeId
          iconName
          idleThreshold
          offlineThreshold
          createdAt
          updatedAt
        }
        course
        projectId
        movingStatus
        movingTs
        movingStartTs
        movedTs
        movedProjectId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const reportsByCustomer = /* GraphQL */ `
  query ReportsByCustomer(
    $customerId: String
    $destProjectIdStopTs: ModelAssetMovementReportByCustomerCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAssetMovementReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportsByCustomer(
      customerId: $customerId
      destProjectIdStopTs: $destProjectIdStopTs
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uuid
        assetId
        startTs
        stopTs
        state
        onRoadDistance
        offRoadDistance
        totalDistanceTraveled
        prevProjectId
        destProjectId
        customerId
        firstMove
        userGroups
        adminGroups
        assetTypeId
        assetSubTypeId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const projectsByCustomerId = /* GraphQL */ `
  query ProjectsByCustomerId(
    $customerId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectsByCustomerId(
      customerId: $customerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        customerId
        client
        description
        startDate
        endDate
        status
        userGroups
        adminGroups
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchAssets = /* GraphQL */ `
  query SearchAssets(
    $filter: SearchableAssetFilterInput
    $sort: SearchableAssetSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchAssets(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        uuid
        createdAt
        updatedAt
        status
        customerId
        deviceId
        name
        userGroups
        adminGroups
        description
        version
        unitNumber
        make
        model
        year
        capacity
        activatedOn
        deactivatedOn
        toolType
        size
        annualInspection
        eventTs
        receivedTs
        firstCheckedIn
        altitude
        battery
        rssi
        accelerometer
        speed
        course
        projectId
        movingStatus
        movingTs
        movingStartTs
        movedTs
        movedProjectId
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const searchProjects = /* GraphQL */ `
  query SearchProjects(
    $filter: SearchableProjectFilterInput
    $sort: SearchableProjectSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProjects(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        customerId
        client
        description
        startDate
        endDate
        status
        userGroups
        adminGroups
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
