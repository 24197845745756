import React, { useEffect, useState } from 'react'
// import { useNavigate } from 'react-router-dom'
import { useSearchActions } from '../../store/Search/SearchActions.js'
import { safeGet } from '../../util.js'
import moment from 'moment'

import { MaterialReactTable } from 'material-react-table'

import AsseTracAppBar from '../elements/AsseTracAppBar.js'

import { Container, Grid2, Typography, } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  searchResultsContainer: {
    marginTop: theme.spacing(2),
    '& .MuiPaper-elevation2': {
      boxShadow: 'none',
    },
  },
  noResults: {
    textAlign: 'center',
  },
}))

export default () => {
  const classes = useStyles()
  // const theme = useTheme()
  const navigate = useNavigate()
  // const tableIcons = theme.tableIcons

  const { searchState } = useSearchActions()
  const [dataType, setDataType] = useState(null)
  const [tableData, setTableData] = useState(null)
  const [columnSchema, setColumnSchema] = useState(null)

  const projectColumns = [
    { header: 'Name', accessorKey: 'name' },
    { header: 'Client', accessorKey: 'client' },
    { header: 'Description', accessorKey: 'Description' },
    { header: 'Start Date', accessorKey: 'startDate' },
    { header: 'End Date', accessorKey: 'endDate' },
  ]

  const assetColumns = [
    {
      header: 'UUID',
      accessorKey: 'uuid',
      cellStyle: { minWidth: '136px' },
    },
    { header: 'Asset', accessorKey: 'name' },
    { header: 'Project', accessorKey: 'project' },
    { header: 'Type', accessorKey: 'type' },
    { header: 'Subtype', accessorKey: 'subType' },
    { header: 'Lat', accessorKey: 'latitude' },
    { header: 'Long', accessorKey: 'longitude' },
    { header: 'Version', accessorKey: 'version' },
    {
      header: 'Last Checked In',
      accessorKey: 'lastCheckedIn',
      cellStyle: { minWidth: '90px' },
    },
  ]

  const rowClick = (rowData) => {
    switch (dataType) {
      case 'projects':
        navigate('/Projects/' + rowData.id)
        break
      case 'assets':
        navigate('/Asset/' + rowData.id)
        break
      default:
        break;
    }
  }

  const massageData = (type, data) => {
    let formattedData
    // todo turn this into a switch
    switch (type) {
      case 'projects':
        formattedData = data.reduce((acc, current) => {
          acc.push({
            id: safeGet('id', current, ''),
            name: safeGet('name', current, ''),
            customerId: safeGet('customerId', current, ''),
            client: safeGet('client', current, ''),
            description: safeGet('description', current, ''),
            startDate: moment
              .unix(safeGet('startDate', current, ''))
              .format('M/D/YY'),
            endDate: moment
              .unix(safeGet('endDate', current, ''))
              .format('M/D/YY'),
            status: safeGet('status', current, ''),
            userGroups: safeGet('userGroups', current, ''),
            adminGroups: safeGet('adminGroups', current, ''),
          })

          return acc
        }, [])

        break
      case 'assets':
        formattedData = data.reduce((acc, current) => {
          acc.push({
            name: safeGet('name', current, ''),
            activatedOn: safeGet('activatedOn', current, ''),
            adminGroups: safeGet('adminGroups', current, ''),
            latitude: safeGet(
              'assetHistory.items.0.coordinate.latitude',
              current,
              '',
            ),
            longitude: safeGet(
              'assetHistory.items.0.coordinate.longitude',
              current,
              '',
            ),
            battery: safeGet('assetHistory.items.0.battery', current, ''),
            rssi: safeGet('assetHistory.items.0.rssi', current, ''),
            nextToken: safeGet('nextToken', current, ''),
            assetMovement: safeGet('assetMovement', current, ''),
            customer: safeGet('customer', current, ''),
            deactivatedOn: safeGet('deactivatedOn', current, ''),
            description: safeGet('description', current, ''),
            lastCheckedIn: moment
              .unix(safeGet('assetHistory.items.0.eventTs', current, ''))
              .format('M/D/YY'),
            firstCheckedIn: safeGet('firstCheckedIn', current, ''),
            id: safeGet('id', current, ''),
            project: safeGet('project.name', current, ''),
            subType: safeGet('subType.name', current, ''),
            type: safeGet('type.name', current, ''),
            userGroups: safeGet('userGroups', current, ''),
            uuid: safeGet('uuid', current, ''),
            version: safeGet('version', current, ''),
          })

          return acc
        }, [])

        break
      default:
        break
    }
    return formattedData
  }
  useEffect(() => {
    if (!searchState.searchResults || !searchState.searchResults[0]) return
    if (Object.prototype.hasOwnProperty.call(searchState.searchResults[0], 'client')) {
      // projects
      setDataType('projects')
      setTableData(massageData('projects', searchState.searchResults))
      setColumnSchema(projectColumns)
    }
    if (Object.prototype.hasOwnProperty.call(searchState.searchResults[0], 'firstCheckedIn')) {
      // assets
      setDataType('assets')
      setTableData(massageData('assets', searchState.searchResults))
      setColumnSchema(assetColumns)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState.searchResults])

  return (
    <Grid2 container direction='column'>
      <AsseTracAppBar title='SEARCH RESULTS' />
      <Container maxWidth='lg' className={classes.container}>
        <Grid2 container>
          <Grid2  xs={12} className={classes.searchResultsContainer}>
            {(!searchState.searchResults ||
              searchState.searchResults.length === 0) && (
                <Typography
                  className={classes.noResults}
                  color='secondary'
                  variant='body2'
                >
                  no results
                </Typography>
              )}
            {searchState.searchResults &&
              searchState.searchResults.length > 0 && (
                <>
                  {tableData && (
                    <MaterialReactTable
                      // icons={tableIcons}
                      // options={{
                      //   toolbar: false,
                      //   pageSize: 10,
                      //   pageSizeOptions: [10, 25, 50, 100],
                      //   emptyRowsWhenPaging: false,
                      //   search: false,
                      //   showTitle: false,
                      //   headerStyle: {
                      //     minHeight: '48px',
                      //   },
                      // }}
                      onRowClick={rowClick}
                      columns={columnSchema}
                      data={tableData}
                    />
                  )}
                </>
              )}
          </Grid2>
        </Grid2>
      </Container>
    </Grid2>
  )
}
