import React, { useState, useEffect } from 'react'
import {
  Button,
  Container,
  Grid2,
  Typography,
  CircularProgress,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import AsseTracAppBar from '../elements/AsseTracAppBar.js'
import SystemIcon from '../elements/SystemIcon.js'
import AddOwnersDialog from '../dialogs/AddOwnersDialog.js'
import { useStateContext } from '../../store/stateContext.js'
import { useCustomerActions } from '../../store/Customer/CustomerActions.js'
import { useUserActions } from '../../store/User/UserActions.js'
import { formatDate } from '../../util.js'
import { SET_USER_LIST } from '../../store/User/UserReducer.js'
import {
  useCancellablePromise,
  canceledPromiseErrorName,
} from '../../promiseUtils.js'

const useStyles = makeStyles((theme) => ({
  pageWrap: {
  },
  alignRight: {
    textAlign: 'right',
  },
  container: {
    marginTop: theme.spacing(3),
  },
  header: {
    justifyContent: 'space-between'
  },
  cellLabel: {
    color: theme.colors.lightSteel,
  },
  listContainer: {
    marginTop: theme.spacing(3),
    paddingRight: theme.spacing(10)
  },
  email: {
    flexGrow: 1
  },
  since: {
    marginRight: theme.spacing(10)
  },
  selectBox: {
    textAlign: 'left',
    minWidth: 120,
  },
  spinnerContainer: {
    minHeight: '200px',
  },
}))

const OwnersPage = () => {
  const classes = useStyles()
  const { getUsersInGroup, usersState } = useUserActions()
  const { customerState } = useCustomerActions()
  const { dispatch } = useStateContext()
  const { cancellablePromise } = useCancellablePromise()

  const [addOwnersOpen, setAddOwnersOpen] = useState(false)
  const [loading, setLoading] = useState(true)

  const refreshOwnerList = async () => {
    try {
      setLoading(true)
      let userList = await cancellablePromise(getUsersInGroup('admins'))

      userList = userList.reduce((acc, current) => {
        acc.push({
          ...current,
          Email: current.Attributes.find((at) => {
            if (at.Name !== 'email') {
              return false
            }
            return true
          }).Value,
        })
        return acc
      }, [])

      dispatch({ type: SET_USER_LIST, payload: { userList } })
      setLoading(false)
      return userList
    } catch (err) {
      if (err.name === canceledPromiseErrorName) {
        return
      }
      console.error(err)
    }
    setLoading(false)
  }

  useEffect(() => {
    refreshOwnerList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerState.activeCustomer])

  return (
    <>
      <Grid2
        container
        direction='column'
        className={classes.pageWrap}
      // width='50%'
      >
        <AsseTracAppBar title='OWNERS' />
        <Container maxWidth='md' className={classes.container}>
          <Grid2 container className={classes.header}>
            <Grid2 xs={6}>
              <Typography color='secondary' variant='subtitle1'>
                {usersState.userList.length}
                {usersState.userList.length === 1 ? (
                  <span> owner</span>
                ) : (
                  <span> total owners</span>
                )}
              </Typography>
            </Grid2>
            <Grid2 xs={6} className={classes.alignRight}>
              <Button
                color='primary'
                onClick={() => setAddOwnersOpen(true)}
                variant='contained'
                sx={{
                  borderRadius: 5
                }}
              >
                <SystemIcon name='icn_add' color='white' /> ADD OWNERS
              </Button>
            </Grid2>
          </Grid2>

          {loading && (
            <Grid2
              container
              alignItems='center'
              justify='center'
              className={classes.spinnerContainer}
            >
              <Grid2 >
                <CircularProgress variant='indeterminate' />
              </Grid2>
            </Grid2>
          )}

          {!loading && (
            <Grid2 container className={classes.listContainer} spacing={2}>
              {usersState.userList.map((user, index) => (
                <Grid2
                  container
                  key={`user-row-${index}`}
                  sx={{
                    width: '100%',
                    justifyContent: 'space-between'
                  }}
                >
                  <Grid2 md className={classes.email}>
                    <Typography color='secondary'>{user.Email}</Typography>
                  </Grid2>
                  <Grid2 xs={3} className={classes.since}>
                    <Typography color='secondary'>
                      <span className={classes.cellLabel}>Since: </span>
                      {formatDate(user.UserCreateDate, 'M/D/Y')}
                    </Typography>
                  </Grid2>

                  <Grid2 xs={3}>
                    <Typography color='secondary'>
                      <span className={classes.cellLabel}>Last Login: </span>
                      {formatDate(user.UserLastModifiedDate, 'M/D/Y')}
                    </Typography>
                  </Grid2>
                </Grid2>
              ))}
            </Grid2>
          )}
        </Container>
      </Grid2>
      <AddOwnersDialog
        closeForm={() => setAddOwnersOpen(false)}
        open={addOwnersOpen}
        onSave={refreshOwnerList}
      />
    </>
  )
}

export default OwnersPage
