import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Grid2,
  TextField,
  DialogContent,
  Button,
} from '@mui/material'

import SpinnerButton from '../elements/SpinnerButton.js'
import { useForm } from '../../hooks/Forms.js'
import { useCustomerActions } from '../../store/Customer/CustomerActions.js'
import { useStateContext } from '../../store/stateContext.js'
import { makeStyles, useTheme } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiPaper-root': {
      borderRadius: '16px',
      border: `1px solid ${theme.colors.lightSteel}`,
    },
  },
  dialogBackground: {
    backgroundColor: theme.colors.backgroundColor
  },
  dialogTitle: {
    color: 'white'
  },
  label: {
    '&.MuiStepLabel-root .MuiStepLabel-label': {
      color: 'white',
    },
  },
 
}))

const CreateCustomerDialog = ({ open, closeForm = () => { } }) => {
  const classes = useStyles()
  const theme = useTheme()

  const { createCustomer } = useCustomerActions()

  const { state } = useStateContext()
  const customersState = state.customers.customerList
  const [customerNames, setCustomerNames] = useState([])

  const validationRules = {
    name: `required|unique:${customerNames.join(',')}`,
    email: 'required|email',
  }

  const {
    clearFormState,
    formState,
    getFieldError,
    getFirstFieldError,
    getValue,
    handleInputChange,
    handleSubmit,
    isValid,
  } = useForm(validationRules)

  useEffect(() => {
    // Set up the validation rules
    // for the name using the customers
    const names = customersState.reduce((acc, cur) => {
      acc.push(cur.name)
      return acc
    }, [])
    setCustomerNames(names)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersState])

  const handleCancelForm = () => {
    closeForm()
    clearFormState()
  }

  const submitForm = async (fields) => {
    await createCustomer(fields)

    closeForm()
    clearFormState()
  }

  return (
    <Dialog fullWidth open={open} onClose={closeForm} className={classes.dialog}>
      <form onSubmit={(e) => handleSubmit(submitForm, e)} className={classes.dialogBackground}>
        <DialogTitle className={classes.dialogTitle}>CREATE CUSTOMER</DialogTitle>
        <DialogContent>
          <Grid2 container spacing={4} direction='column' sx={{ marginTop: 3 }}>
            <Grid2 xs={12}>
              <TextField
                autoFocus
                fullWidth
                variant='outlined'
                label='Customer Name'
                onChange={handleInputChange('name')}
                value={getValue('name')}
                data-test='creatCustomerDialogName'
                error={!!getFieldError('name')}
                helperText={getFirstFieldError('name')}
                sx={theme.textfield}
              />
            </Grid2>
            <Grid2 xs={12}>
              <TextField
                fullWidth
                variant='outlined'
                label='Admin Email'
                onChange={handleInputChange('email')}
                value={getValue('email')}
                data-test='creatCustomerDialogEmail'
                error={!!getFieldError('email')}
                helperText={getFirstFieldError('email')}
                sx={theme.textfield}
              />
            </Grid2>
          </Grid2>
        </DialogContent>
        <DialogActions>
          <Button
            color='secondary'
            onClick={handleCancelForm}
            data-test='creatCustomerDialogCancelButton'
          >
            Cancel
          </Button>
          <SpinnerButton
            loading={formState.loading}
            type='submit'
            valid={isValid()}
            data-test='creatCustomerDialogSubmitButton'
          >
            Submit
          </SpinnerButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default CreateCustomerDialog
