import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  TextField,
  Box,
  Chip,
} from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import find from 'lodash/find.js'
import SpinnerButton from '../elements/SpinnerButton.js'
import { useForm } from '../../hooks/Forms.js'
// import Validator from '../../libs/Validator.js'
import { useUserActions } from '../../store/User/UserActions.js'
import { useCustomerActions } from '../../store/Customer/CustomerActions.js'

const userTypes = ['User', 'Admin']

// const validator = new Validator({
//   email: 'email',
// })

const useStyles = makeStyles((theme) => ({
  errorMsg: {
    color: theme.palette.error.main,
  },
  form: {
    backgroundColor: theme.colors.backgroundColor,
    color: 'white'
  },
  dialog: {
    '& .MuiPaper-root': {
      borderRadius: '16px',
      border: `1px solid ${theme.colors.lightSteel}`,
    },
  },
  select: {
    color: 'white',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.lightSteel,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.lightSteel,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.lightSteel,
    },
    '& .MuiSvgIcon-root': {
      color: theme.colors.lightSteel,
    },
    '& .MuiSelect-select': {
      color: 'white',
      width: 130,
    },
  },

}))

const InviteUsersDialog = ({ closeForm, onSave = () => { }, open }) => {
  const classes = useStyles()
  const theme = useTheme()

  const {
    clearFormState,
    formState,
    getValue,
    handleInputChange,
    handleSubmit,
    isValid,
  } = useForm({
    emails: 'required|email',
    userType: 'required',
  })

  // const [formError, setFormError] = useState()
  const { inviteUsers } = useUserActions()
  const { getActiveCustomer } = useCustomerActions()

  const handleCancelForm = () => {
    closeForm()
    clearFormState()
  }

  // const handleBeforeAdd = (email) => {
  //   if (!email) {
  //     return false
  //   }

  //   const error = validator.validate({ email })

  //   setFormError(error && error.email[0])

  //   return error === null
  // }

  const onSubmit = async (fields) => {
    const userGroups = ((await getActiveCustomer()) || {}).userGroups || []
    const customerGroup = find(userGroups, (v) => v.indexOf('customer-') > -1)

    if (!customerGroup) {
      throw new Error('Customer group not available')
    }

    await inviteUsers({
      ...fields,
      customerGroup,
    })

    closeForm()
    clearFormState()
    onSave()
  }

  return (
    <Dialog fullWidth onClose={closeForm} open={open} className={classes.dialog}>
      <form onSubmit={(e) => handleSubmit(onSubmit, e)} className={classes.form}>
        <DialogTitle>INVITES USERS</DialogTitle>
        <DialogContent>
          <Grid2 container spacing={2} sx={{ marginTop: 1 }}>
            <Grid2 sx={{ flexGrow: 1 }}>
              <FormControl fullWidth>
                {/* <ChipInput
                  blurBehavior='add'
                  fullWidth
                  onBeforeAdd={handleBeforeAdd}
                  onChange={handleInputChange('emails')}
                  label='EMAIL ADDRESSES'
                  variant='outlined'
                /> */}
                {/* TODO: Finish migration */}
                <TextField
                  label='EMAIL ADDRESSES'
                  // fullWidth
                  onChange={handleInputChange('emails')}
                  variant='outlined'
                  sx={theme.textfield}
                >
                  <Box>
                    <Chip />
                  </Box>
                </TextField>
                <FormHelperText className={classes.errorMsg}>
                  {/* {formError} */}
                </FormHelperText>
              </FormControl>
            </Grid2>
            <Grid2 >
              <Select
                fullWidth
                onChange={handleInputChange('userType')}
                value={getValue('userType', 'User')}
                variant='outlined'
                className={classes.select}
              >
                {userTypes.map((type, index) => (
                  <MenuItem key={`user-type-${index}`} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </Grid2>
          </Grid2>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' onClick={handleCancelForm}>
            Cancel
          </Button>
          <SpinnerButton
            loading={formState.loading}
            type='submit'
            valid={isValid()}

          >
            Submit
          </SpinnerButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default InviteUsersDialog
