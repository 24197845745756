import React from 'react'
import { Button, CircularProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  spinner: {
    position: 'absolute',
  },
}))

const SpinnerButton = (props) => {
  const classes = useStyles()
  const loading = props.loading || false

  const newProps = {
    // set defaults
    variant: 'contained',
    color: 'primary',

    // merge user props
    ...props,

    // override for special functionality
    disabled: props.disabled || !props.valid || loading,
  }

  // remove custom props
  if (Object.prototype.hasOwnProperty.call(newProps, 'loading')) {
    Reflect.deleteProperty(newProps, 'loading')
  }
  if (Object.prototype.hasOwnProperty.call(newProps, 'valid')) {
    Reflect.deleteProperty(newProps, 'valid')
  }

  return (
    <Button {...newProps} sx={{borderRadius: 5}}>
      {loading && <CircularProgress className={classes.spinner} size={24} />}
      {props.children}
    </Button>
  )
}

export default SpinnerButton
