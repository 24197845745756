import { fetchAuthSession } from 'aws-amplify/auth'
import { useStateContext } from '../stateContext.js'
import { SET_USER_LIST } from './UserReducer.js'
import { post, get } from 'aws-amplify/api'

const apiName = 'AdminQueries'

export const useUserActions = () => {
  const { state, dispatch } = useStateContext()
  const addUserToGroup = async (username, groupname) => {
    const path = '/addUserToGroup';
    const session = await fetchAuthSession()

    const request = {
      body: {
        username,
        groupname,
      },
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${session.tokens.accessToken}`
      },
    }

    return await post({ apiName, path, options: request }).response
  }

  const removeUserFromGroup = async (username, groupname) => {
    const path = '/removeUserFromGroup'
    const session = await fetchAuthSession()
    const request = {
      body: {
        username,
        groupname,
      },
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${session.tokens.accessToken}`
      },
    }

    return await post({ apiName, path, options: request }).response
  }

  const enableUser = async (username) => {
    const path = '/enableUser'
    const session = await fetchAuthSession()
    const request = {
      body: {
        username,
      },
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${session.tokens.accessToken}`
      },
    }

    return await post({ apiName, path, options: request }).response
  }

  const disableUser = async (username) => {
    const path = '/disableUser'
    const session = await fetchAuthSession()
    const request = {
      body: {
        username,
      },
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${session.tokens.accessToken}`
      },
    }

    return await post({ apiName, path, options: request }).response
  }

  const createGroup = async (groupname) => {
    const path = '/createGroup'
    const session = await fetchAuthSession()
    const request = {
      body: {
        groupname,
      },
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${session.tokens.accessToken}`
      },
    }

    return await post({ apiName, path, options: request }).response
  }

  const createUser = async (username) => {
    const path = '/createUser'
    const session = await fetchAuthSession()
    const request = {
      body: {
        username,
      },
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${session.tokens.accessToken}`
      },
    }

    const user = await post({ apiName, path, options: request }).response

    return user
  }

  const getUser = async (username) => {
    const path = '/getUser'
    const session = await fetchAuthSession()
    const request = {
      queryParams: {
        username,
      },
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${session.tokens.accessToken}`
      },
    }
    const response = await get({ apiName, path, options: request }).response
    const user = await response.body.json()
    return user
  }

  const getUsers = async () => {
    const path = '/listUsers'
    const request = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const userList = await get({ apiName, path, options: request }).response

    dispatch({ type: SET_USER_LIST, payload: { userList } })

    return userList
  }

  const getUsersInGroup = async (groupname) => {
    const path = '/listUsersInGroup'
    const session = await fetchAuthSession()
    const request = {
      queryParams: {
        groupname,
      },
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${session.tokens.accessToken}`
      },
    }

    const response = await get({ apiName, path, options: request }).response
    const { Users } = await response.body.json()
    dispatch({ type: SET_USER_LIST, payload: { userList: Users } })

    return Users
  }

  const inviteUsers = async ({ customerGroup, emails, userType = 'User' }) => {
    try {
      if (!customerGroup || !emails || !userType) {
        throw new Error('customerGroup, emails, and userType required.')
      }

      const path = '/customerInviteUsers'
      const session = await fetchAuthSession()

      const request = {
        body: {
          customerGroup,
          userType,
          emails: typeof emails === 'string' ? [emails] : emails,
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.tokens.accessToken}`
        },
      }

      return await post({ apiName, path, options: request }).response

    } catch (error) {
      throw new Error(error)
    }
  }

  return {
    addUserToGroup,
    removeUserFromGroup,
    enableUser,
    disableUser,
    createGroup,
    createUser,
    getUser,
    getUsers,
    getUsersInGroup,
    inviteUsers,
    usersState: state.users,
  }
}
