import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid2,
  Typography,
} from '@mui/material'

import { useStateContext } from '../../store/stateContext.js'
import { useUIActions } from '../../store/UI/UIActions.js'
import { useAssetsActions } from '../../store/Assets/AssetsActions.js'
import SpinnerButton from '../elements/SpinnerButton.js'

const RetireAssetDialog = ({ asset, onSubmit }) => {
  asset = asset || {}
  const { editAsset } = useAssetsActions()

  const { closeRetireAssetDialog } = useUIActions()
  // const { retireAsset } = useAssetsActions()

  // app level state
  const [sending, setSending] = useState(false)
  const { state } = useStateContext()

  // component level actions
  const clearForm = () => {
    setSending(false)
  }

  const cancelForm = () => {
    closeRetireAssetDialog()
  }

  const submit = async () => {
    try {
      setSending(true)
      const tempAsset = {
        ...asset,
        status: 'retired',
      }
      await editAsset(tempAsset).then((res) => {
        // call onSubmit prop
        if (typeof onSubmit === 'function') {
          onSubmit(res.data.updateAsset)
        }
      })

      closeRetireAssetDialog()
      clearForm()
    } catch (err) {
      setSending(false)
      throw err
    }
  }

  return (
    <Dialog
      fullWidth
      scroll='body'
      open={state.ui.retireAssetDialogOpen}
      onClose={closeRetireAssetDialog}
    >
      <DialogContent>
        <DialogTitle>RETIRE ASSET</DialogTitle>
        <Grid2  xs={12}>
          <Typography>
            Retiring an asset will permanently stop all tracking of this asset.
            This retirement is irreversible.
          </Typography>
        </Grid2>
      </DialogContent>
      <DialogActions>
        <Button color='secondary' onClick={cancelForm}>
          Cancel
        </Button>
        <SpinnerButton loading={sending} type='submit' onClick={submit} valid>
          Retire
        </SpinnerButton>
      </DialogActions>
    </Dialog>
  )
}

export default RetireAssetDialog
