import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid2, useTheme, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import moment from 'moment'

import AssetDetails from '../elements/AssetDetails.js'
import AsseTracAppBar from '../elements/AsseTracAppBar.js'
import AsseTracMap from '../elements/AsseTracMap.js'
import TimelineSlider from '../elements/TimelineSlider/index.js'
import AssetToolTip from '../elements/AssetTooltip.js'

import { useStateContext } from '../../store/stateContext.js'
import { useAssetsActions } from '../../store/Assets/AssetsActions.js'
import { useMapActions } from '../../store/Map/MapActions.js'
import { SET_ICONLAYERS } from '../../store/Map/MapReducer.js'

import { debounce, safeGet, formatDateTime } from '../../util.js'
import {
  canceledPromiseErrorName,
  useCancellablePromise,
} from '../../promiseUtils.js'

const useStyles = makeStyles((theme) => ({
  pageWrap: {
    [theme.breakpoints.up('md')]: {
      height: '100%',
      flexWrap: 'unset',
    },
  },
  contentWrap: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  mapWrap: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      flexGrow: 'unset',
      flexBasis: 'unset',
      height: '60vh',
      minHeight: 200,
      maxHeight: 400,
    },
  },
  timelineWrap: {
    position: 'relative',
    flexBasis: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      flexBasis: theme.spacing(10.25),
    },
  },
  drawer: {
    width: theme.spacing(44),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxHeight: 'unset',
      flexGrow: 1,
    },
    [theme.breakpoints.down('xs')]: {
      flexGrow: 2,
    },
  },
  assetDetails: {
    width: theme.spacing(44),
    padding: theme.spacing(2),
    position: 'fixed',
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '100%',
    borderLeftWidth: 1,
    borderLeftStyle: 'solid',
    borderLeftColor: theme.colors.lightSteel,
    /* eslint-disable no-dupe-keys */
    /* Firefox */
    maxHeight: ' -moz-calc(100vh - 48px)',
    /* WebKit */
    maxHeight: '-webkit-calc(100vh - 48px)',
    /* Opera */
    maxHeight: '-o-calc(100vh - 48px)',
    /* Standard */
    maxHeight: 'calc(100vh - 48px)',
    /* eslint-enable no-dupe-keys */
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxHeight: 'unset',
      flexGrow: 1,
      position: 'unset',
      overflow: 'unset',
      borderLeft: 'unset',
    },
  },
  timeline: {
    width: '100%',
    position: 'absolute',
    left: 0,
    bottom: 0,
    zIndex: 10,
  },
}))

const AssetPage = () => {
  const classes = useStyles()
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))
  const { id: assetId } = useParams()
  const { dispatch, state } = useStateContext()
  const { getAssetFromDB, getAssetHistory } = useAssetsActions()
  const { addWaypointLayer, clearLayers, zoomToCoordinates } = useMapActions()
  const { cancellablePromise } = useCancellablePromise()
  const [asset, setAsset] = useState()
  const [assetHistory, setAssetHistory] = useState([])
  const [mapReady, setMapReady] = useState(false)
  const [timelineDate, setTimelineDate] = useState()

  useEffect(() => {
    // on mount reset
    clearLayers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (assetId && mapReady) {
      let localAsset = state.assets.assetList.find(
        (asset) => asset.id === assetId,
      )
      if (!localAsset) {
        localAsset = state.assets.projectAssetList.find(
          (asset) => asset.id === assetId,
        )
        if (!localAsset && !asset) {
          cancellablePromise(getAssetFromDB(assetId))
            .then(setAsset)
            .catch((error) => {
              if (error.name === canceledPromiseErrorName) {
                return
              }
              console.error(error)
            })

          return
        }
      }
      if (localAsset) {
        setAsset(localAsset)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.assets.assetList, state.assets.projectAssetList, assetId, mapReady])

  useEffect(() => {
    if (!asset) {
      return
    }
    const currentLocation = asset.coordinate || {}

    const historicPoints = assetHistory.reduce((acc, cur) => {
      acc.push({
        longitude: cur.coordinate.longitude,
        latitude: cur.coordinate.latitude,
      })
      return acc
    }, [])

    if (currentLocation) {
      zoomToCoordinates([currentLocation, ...historicPoints])
    }
    dispatch({
      type: SET_ICONLAYERS,
      payload: {
        iconLayers: [
          {
            id: safeGet('id', asset),
            icon: safeGet('subType.iconName', asset),
            unitNumber: safeGet('unitNumber', asset),
            typeName: safeGet('type.name', asset),
            subTypeName: safeGet('subType.name', asset),
            lastReceived: formatDateTime(
              safeGet('receivedTs', asset),
              'M/D/YY h:mmA',
            ),
            coordinates: currentLocation,
            onClick(info) {
              console.log(info)
            },
          },
        ],
      },
    })

    if (assetHistory && assetHistory.length > 0) {
      addWaypointLayer(assetId, {
        currentLocation,
        moves: assetHistory,
        asset,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset, assetHistory])

  useEffect(() => {
    let isSubscribed = true
    if (timelineDate) {
      const callbackFunction = isSubscribed ? setAssetHistory : null
      debounce(
        'assetPage_history',
        () => {
          const startTimestamp = moment(timelineDate).subtract(1, 'day').unix()
          const endTimestamp = moment(timelineDate).add(1, 'day').unix()
          getAssetHistory(
            {
              assetId: assetId,
              eventTs: {
                between: [startTimestamp, endTimestamp],
              },
            },
            asset,
            callbackFunction,
          )
        },
        300,
      )
    }
    return () => (isSubscribed = false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelineDate])

  return (
    <>
      <Grid2 container direction='column' className={classes.pageWrap}>
        <AsseTracAppBar
          title={(asset && asset.unitNumber) || 'ASSET DETAILS'}
          icon={(asset && asset.subType && asset.subType.iconName) || ''}
        />
        <Grid2
          container
          xs={mdUp}
          className={classes.contentWrap}
          direction='row'
        >
          <Grid2 container xs={mdUp} direction='column'>
            <Grid2 sm className={classes.mapWrap}>
              <AsseTracMap onInitialize={() => setMapReady(true)} />
              <AssetToolTip tooltip={state.map.tooltip} />{' '}
            </Grid2>
            <Grid2 className={classes.timelineWrap}>
              <TimelineSlider
                className={classes.timeline}
                onChange={setTimelineDate}
              />
            </Grid2>
          </Grid2>
          <Grid2 container className={classes.drawer}>
            <Grid2 className={classes.assetDetails}>
              <AssetDetails
                asset={asset}
                assetHistory={assetHistory}
                setAsset={setAsset}
              />
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>
    </>
  )
}

export default AssetPage
