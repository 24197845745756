/* eslint-disable no-unused-vars */

import React, { useState } from 'react'
import moment from 'moment'
import { useUIActions } from '../../store/UI/UIActions.js'
import { safeGet } from '../../util.js'

import {
  Grid2,
  IconButton,
  Typography,
  SvgIcon,
  Button,
  Popover
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import Icons from '../../assets/icons/symbol-defs.svg'

const useStyles = makeStyles(() => ({
  clientName: {
    fontWeight: 'bold'
  },
  projectDescription: {
    fontWeight: 'bold'
  },
  label: {
    fontWeight: 'bold'
  },
}))

const ProjectInfo = ({ project }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null);
  const { openUpdateProjectDialog, openInactivateProjectDialog } =
    useUIActions()

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid2 container sx={{
      alignSelf: 'center',
      paddingLeft: 2,
      flexGrow: 1,
      alignItems: 'center'
    }}>
      <Typography variant='h6'>
        {safeGet('name', project)}
      </Typography>
      <IconButton
        onClick={handleClick}
        sx={{
          marginLeft: 1,
        }}
        size='small'
      >
        <SvgIcon fontSize='medium' sx={{ fill: 'white' }}>
          <svg>
            <use xlinkHref={Icons + '#icn_caret_down'} />
          </svg>
        </SvgIcon>
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}

      >
        <Grid2 container spacing={1} direction='column' sx={{ padding: 2 }}>
          <Typography
            className={classes.clientName}
            variant='subtitle2'
          >
            {safeGet('client', project)}
          </Typography>
          <Typography
            className={classes.projectDescription}
            variant='body2'
          >
            {safeGet('description', project)}
          </Typography>
          <Typography variant='body1'>
            <span className={classes.label}>START DATE&nbsp;&nbsp;</span>
            {project && `${moment(project.startDate).format('MM/DD/YYYY')}`}
          </Typography>
          <Typography variant='body1'>
            <span className={classes.label}>END DATE&nbsp;&nbsp;</span>
            {project && `${moment(project.endDate).format('MM/DD/YYYY')}`}
          </Typography>
          <Grid2 sx={{
            marginTop: 1,
          }}>
            <Button
              onClick={openInactivateProjectDialog}
              color='primary'
              variant='outlined'
              disabled={
                project.status === 'activating' ||
                project.status === 'deactivating'
              }
              sx={{ marginRight: 1 }}
            >
              {project.status === 'active' && 'Inactivate Project'}
              {project.status === 'inactive' && 'Re-Activate Project'}
              {project.status === 'activating' && 'Project Activating'}
              {project.status === 'deactivating' && 'Project Deactivating'}
            </Button>
            <Button
              onClick={openUpdateProjectDialog}
              color='primary'
              variant='contained'
              disabled={
                project.status === 'inactive' ||
                project.status === 'activating' ||
                project.status === 'deactivating'
              }
            >
              Edit
            </Button>
          </Grid2>
        </Grid2>
      </Popover>
    </Grid2>
  )
}

export default ProjectInfo
