import React from 'react'
import { Link } from 'react-router-dom'
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import SystemIcon from '../SystemIcon.js'
import { useAuthActions } from '../../../store/Auth/AuthActions.js'
import { useUIActions } from '../../../store/UI/UIActions.js'

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  list: {
    marginLeft: theme.spacing(2),

  },
  nestedList: {
    position: 'relative',
    // marginLeft: theme.spacing(5),
    '&::before': {
      content: '""',
      display: 'block',
      width: '6px',
      height: '91%',
      position: 'absolute',
      left: 0,
      borderRadius: '3px',
      backgroundColor: theme.colors.lightSteel,
    },
  },
}))

const AccountNav = () => {
  const classes = useStyles()
  const { memberOf } = useAuthActions()
  const { closeDrawerNav } = useUIActions()
  const open = true

  const nestedListSX = {
    marginLeft: 7
  }

  return (
    <>
      {memberOf('admins') && (
        <List>
          <ListItem className={classes.list}>
            <ListItemIcon>
              <SystemIcon name='icn_global_settings' color='white' />
            </ListItemIcon>
            <ListItemText>OWNER SETTINGS</ListItemText>
          </ListItem>
          <Collapse in={open}>
            <List className={classes.nestedList} sx={nestedListSX} onClick={closeDrawerNav}>
              <Link to='/Owners' >
                <ListItem
                  className={classes.nested}
                  data-test='userNavButton'
                >
                  <ListItemButton>
                    <ListItemText>OWNERS</ListItemText>
                  </ListItemButton>
                </ListItem>
              </Link>

              <Link to='/customers'>
                <ListItem
                  className={classes.nested}
                  data-test='customerNavButton'
                >
                  <ListItemButton>
                    <ListItemText>CUSTOMERS</ListItemText>
                  </ListItemButton>
                </ListItem>
              </Link>

              <Link to='/GlobalAssetTypes'>
                <ListItem
                  className={classes.nested}
                  data-test='assetTypeNavButton'
                >
                  <ListItemButton>
                    <ListItemText>GLOBAL ASSET TYPES</ListItemText>
                  </ListItemButton>
                </ListItem>
              </Link>
            </List>
          </Collapse>
        </List>
      )}

      <List >
        <ListItem className={classes.list}>
          <ListItemIcon>
            <SystemIcon name='icn_compass' color='white'/>
          </ListItemIcon>
          <ListItemText>SETUP</ListItemText>
        </ListItem>
        <Collapse in={open}>
          <List className={classes.nestedList} sx={nestedListSX} onClick={closeDrawerNav}>
            {memberOf(/admins|customerAdmin[\S]+/) && (
              <Link to='/Users'>
                <ListItem
                  className={classes.nested}
                  data-test='userNavButton'
                >
                  <ListItemButton>
                    <ListItemText>USERS</ListItemText>
                  </ListItemButton>
                </ListItem>
              </Link>
            )}
            <Link to='/Account'>
              <ListItem
                className={classes.nested}
                data-test='accountNavButton'
              >
                <ListItemButton>
                  <ListItemText>MY ACCOUNT</ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
          </List>
        </Collapse>
      </List>
    </>
  )
}

export default AccountNav
