/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react'
import { downloadData } from 'aws-amplify/storage'
import CheckboxTree from './CheckboxTree.js'
import KmlLayer from '../../models/KmlLayer.js'
import {
  useCancellablePromise,
  canceledPromiseErrorName,
} from '../../promiseUtils.js'

const KmlTree = ({ kmlFile, onSelect = () => { }, s3Object }) => {
  const { cancellablePromise } = useCancellablePromise()
  const [kmlStructure, setKmlStructure] = useState()

  const getStructuredGeoJsonFile = async (s3Object) => {
    try {
      const key = s3Object.key
      const response = await cancellablePromise(
        downloadData({
          path: key,

        }).result
      )
      const kmlAsString = await cancellablePromise(response.body.text())
      const kmlAsGeoJson = JSON.parse(kmlAsString)
      return kmlAsGeoJson
    } catch (error) {
      if (error.name === canceledPromiseErrorName) {
        return
      }
      console.error(error)
    }
  }

  useEffect(() => {
    if (kmlFile) {
      cancellablePromise(kmlFile.getStructure())
        .then(() => setKmlStructure)
        .catch((error) => {
          if (error.name === canceledPromiseErrorName) {
            return
          }
          console.error(error)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kmlFile])

  useEffect(() => {
    if (s3Object) {
      cancellablePromise(getStructuredGeoJsonFile(s3Object))
        .then((kmlAsGeoJson) => {
          setKmlStructure(
            new KmlLayer({
              kmlLayerAsGeoJson: kmlAsGeoJson,
            }),
          )
        })
        .catch((error) => {
          if (error.name === canceledPromiseErrorName) {
            return
          }
          console.error(error)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s3Object])

  return (
    <>
      {kmlStructure && (
        <CheckboxTree onSelect={onSelect} structure={kmlStructure} />
      )}
    </>
  )
}

export default KmlTree
